import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function AboutUs() {
  return (
    <>
    <div className='home'>
        <Navbar/>
        <div className='about'>
        <h1>Welcome to BuyMyProject</h1>
        <br/>
        <div className='about-div'>
        <header>
       
        <p>The ultimate online marketplace for student innovations! Like a perfect cup of coffee, we blend creativity and opportunity to give students, freelancers, and innovators a platform where their projects can thrive.</p>
    </header>

    <section>
        <p>At BuyMyProject, we believe in empowering young minds, turning their academic efforts into real-world solutions that can be enjoyed and monetized.</p>
    </section>

    <section>
        <h2>Our Story</h2>
        <p>The idea for BuyMyProject was brewed from a personal journey, much like how coffee grounds steep into something bold and energizing. As a student, I always wondered what happened to the projects we submitted after exams. We got our marks and moved on—but what about the projects we poured our energy into creating? This question became even more pronounced during my college years. When I was asked to copy a project from seniors, I had one burning question—“Why?” Why can’t we come up with something fresh and new?</p>

        <p>My friends and I eventually brewed our own project from scratch, something meaningful. While we won a few prizes, we quickly realized that was as far as it could go. As students from a middle-class background at a Tier 2 college, our resources were limited. Despite our efforts to sell the project to fund further development, we had to shelve the idea. But like a strong shot of espresso, that idea lingered.</p>

        <p>After 10 years in the IT industry, during a conversation with my students, one of them asked, “Sir, what do we do with our projects? Can we sell them?” This question instantly reignited that same spark within me. I answered, “Not sure—But very soon.” That day marked the beginning of Project Kimberlite. Much like coffee, where patience and the right blend reveal richness, Kimberlite symbolizes the potential to discover diamonds in student innovations.</p>
    </section>

    <section>
        <h2>Our Mission</h2>
        <p>Each year, millions of students craft projects, and most of the time, they are left to fade. BuyMyProject is here to change that. Our platform allows students to join as innovators, giving them the chance to earn royalties and take their projects beyond the classroom. Buyers get to savor the finest innovations, straight from the minds of their creators.</p>

        <p>We are on a mission to revolutionize how students create, learn from, and shape the future of their projects—just like the perfect brew of ideas. Help us make this mission a reality.</p>
    </section>
    <p>For any inquiries or support, feel free to contact us at <a href="mailto:ceo@bonginnovations.io">ceo@bonginnovations.io</a>.</p>


        </div>

        </div>

        <Footer/>
    </div>

    </>
  )
}

export default AboutUs