import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import icon from "../Assets/profileicon2.png";
import icon2 from "../Assets/Icon.png";
import admin1 from "../Assets/adminhome (1).png";
import admin2 from "../Assets/adminhome (2).png";
import admin3 from "../Assets/adminhome (3).png";
import admin4 from "../Assets/adminhome (4).png";
import admin5 from "../Assets/adminhome (5).png";
import admin6 from "../Assets/adminhome (6).png";
import "./adminnavbar.css"
import logo from '../Assets/bong.png'

function AdminNavbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDivRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem("bong_innovator_token");
    const user = JSON.parse(localStorage.getItem("bong_innovator")||null);

    if (token) {
      setIsLoggedIn(true);
      setUserName(user.name);
    }
  }, []);

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("bong_innovator_token");
    localStorage.removeItem("bong_innovator");
    setIsLoggedIn(false);
    navigate("/"); 
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Function to open the toggle menu
  const openToggleDiv = () => {
    setIsDivVisible(true);
  };

  // Function to close the toggle menu
  const closeToggleDiv = () => {
    setIsDivVisible(false);
  };

  // Function to handle click outside of the toggle div
  const handleClickOutside = (event) => {
    if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
      closeToggleDiv(); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    if (isDivVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDivVisible]);

  return (
    <>
      <nav>
        <Link>
          <img className="navlogo" src={logo}/>
        </Link>
        <ul>
          <NavLink to="/adminhome" className="none2" activeClassName="active">
            <li>
              <img src={admin1} alt="Home" />
              Home
            </li>
          </NavLink>
          <NavLink to="/dashboard" className="none2">
            <li>
              <img src={admin1} alt="Dashboard" />
              Dashboard
            </li>
          </NavLink>
          <NavLink to="/addproduct" className="none2">
            <li>
              <img src={admin2} alt="Add Product" />
              Add Product
            </li>
          </NavLink>
          <NavLink to="/allproducts" className="none2">
            <li>
              <img src={admin2} alt="Products" />
              Products
            </li>
          </NavLink>
          <NavLink to="/order" className="none2">
            <li>
              <img src={admin3} alt="Orders" />
              Orders
            </li>
          </NavLink>
          <NavLink to="/webproduct" className="none2">
            <li>
              <img src={admin3} alt="webproduct" />
              Web Product
            </li>
          </NavLink>
          {/* <NavLink to="/customers" className="none2">
            <li>
              <img src={admin4} alt="Customers" />
              Customers
            </li>
          </NavLink>
          <NavLink to="/invoices" className="none2">
            <li>
              <img src={admin5} alt="Invoices" />
              Invoices
            </li>
          </NavLink>
          <NavLink to="/transactions" className="none2">
            <li>
              <img src={admin6} alt="Transactions" />
              Transactions
            </li>
          </NavLink> */}
        </ul>
        <div className="right-nav">
          {isLoggedIn ? (
            <div className="profile-container" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
              <img src={icon} alt="Profile Icon" className="profile-icon" />
              {showDropdown && (
                <div className="profile-dropdown">
                  <p>{userName}</p>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              )}
            </div>
          ) : (
            <NavLink to="/login">
              <button className="nav-login">Login</button>
            </NavLink>
          )}
        </div>
        <i
          style={{ color: "black", cursor: "pointer" }}
          className="bi bi-list"
          onClick={isDivVisible ? closeToggleDiv : openToggleDiv}></i>
      </nav>
      {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
              <NavLink exact className="none" activeClassName="active" to="/">
                <li>Home</li>
              </NavLink>
              <NavLink
                className="none"
                activeClassName="active"
                to="/adminhome">
                <li>Dashboard</li>
              </NavLink>
              <NavLink
                className="none"
                activeClassName="active"
                to="/addproduct">
                <li>Add Products</li>
              </NavLink>
              <NavLink
                className="none"
                activeClassName="active"
                to="/allproducts">
                <li>Products</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/order">
                <li>Orders</li>
              </NavLink>
              <NavLink
                className="none"
                activeClassName="active"
                to="/customers">
                <li>Customers</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/invoices">
                <li>Invoices</li>
              </NavLink>
              <NavLink
                className="none"
                activeClassName="active"
                to="/transactions">
                <li>Transactions</li>
              </NavLink>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminNavbar;
