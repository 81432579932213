import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function NoWorries() {
  return (
    <>
        <div className='home'>
        <Navbar/>
        <div className='noworry'>
        <div className='noworry-left'>
        <h1><span>No GSTIN?</span> No Worries!</h1>
        <h1><span>Composition GSTIN?</span>  No Worries!!</h1>
        <p>Whether you're a big business or a small one, now sell
        to millions in your state, without a Regular GSTIN</p>
        <br/>
        <br/>
<div className='noworry-btns'>
  <button className='noworry-btns-1'>Register Now</button>
  <button className='noworry-btns-2'>and Start Selling</button>

  
</div>


</div>
  <div className='noworry-right'>

</div>

        </div>
        <Footer/>

        </div>
    </>
  )
}

export default NoWorries