import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiRequest from "../../common/apiRequest";
import Loader from "../../loader/Loader";
import { showErrorToast, showSuccessToast } from "../../common/toast";
import cart from "../../Assets/cart.png"; // Cart icon for add to cart functionality

const AllSoftProduct = () => {
  const navigate = useNavigate();
  const [softProducts, setSoftProducts] = useState([]); // State to hold the list of soft products
  const [loading, setLoading] = useState(true); // Loader state

  // Fetch soft products from the API
  useEffect(() => {
    const fetchSoftProducts = async () => {
      setLoading(true);
      try {
        const response = await apiRequest("/softproduct/all", "GET"); // API request to fetch soft products
        setSoftProducts(response?.data || []); // Assuming the response contains the soft products
        // showSuccessToast('Soft Products loaded successfully'); // Success toast
      } catch (error) {
        console.error("Error fetching soft products:", error);
        showErrorToast('Failed to load soft products'); // Error toast
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchSoftProducts();
  }, []);
 

  return (
    <div className="soft-products-div">
      <div className="products-div-text">
        {loading && <Loader />} {/* Show loader during loading */}
        <h4>Web Projects</h4>
        <p>
          Showing {softProducts?.length || 0} Soft Products
        </p>
      </div>
      <br />
      <div className="filter">
        <p>Filters</p>
      </div>
      <br />
      <br />

      <div className="shipping-cards">
        {softProducts.map((softProduct, index) => (
          <div key={index}>
            <div className="shipping-card">
              <img
                src={softProduct?.productImage || "default_image.png"} // Placeholder image
                alt={softProduct?.productName}
                onClick={() => navigate(`/admin/softproductdetail/${softProduct?._id}`)} // Navigate to product detail page
                style={{ cursor: "pointer" }}
              />
              <br />
              <br />
              <h3>{softProduct?.productName}</h3>
              <p>Type: {softProduct?.productType}</p>
              <p>Version: {softProduct?.softwareVersion}</p>
              <p>Seller: {softProduct?.sellerId?.name || "Unknown Seller"}</p>
              <br />
              <br />
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />

      {/* <div className="center">
        <button className="allpdt-btn">View More</button>
      </div> */}
    </div>
  );
};

export default AllSoftProduct;
