import React, { useState, useEffect } from "react";
import img3 from "../Assets/image 146.png";
import cart from "../Assets/cart.png";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Profileimg from "../Assets/Ellipse 11.png";
import apiRequest from "../common/apiRequest";
import { showErrorToast, showSuccessToast } from "../common/toast";
import { FaEdit, FaPen } from "react-icons/fa"; // Import edit icon
import Loader from "../loader/Loader";

function Profile() {
  const [activeTab, setActiveTab] = useState(1);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchProfile = async () => {
    try {
      const response = await apiRequest("/user/self", "GET");
      setProfileData(response?.data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      setLoading(false); // Stop loading once the request is done
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      uploadImage(file); // Upload the image
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    setLoading(true);
    try {
      const response = await apiRequest("/user/update", "PUT", formData);

      if (response.data) {
        fetchProfile();
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error while uploading image:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="home">
        <Navbar />
        {loading && <Loader />}
        <div className="Profile">
          <div className="Profile-div">
            <div className="Profile-div-heading"></div>
            <br />
            <br />
            <div className="Profile-section">
              <div className="Profile-div-imgs">
                <div className="Profile-div-img">
                  <div>
                  <img src={profileData?.image||Profileimg} alt="Profile"  style={{width:"120px", height:"120px", borderRadius:"50%"}}/>
                  {/* Edit icon on top of image */}
                  <label
                    htmlFor="fileInput"
                    className="edit-icon"
                    style={{
                      position: "relative",
                      top: "-50px",
                      right: "5px",
                      cursor: "pointer",
                      color:"grey"
                    }}>
                    <FaPen size={20} />
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  </div>
                  <p>{profileData?.name || "Alexa Rawles"}</p>
                </div>
                <button>Edit</button>
              </div>

              <br />
              <br />
              <div className="tab-container">
                <div className="tabs">
                  <button
                    className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
                    onClick={() => handleTabChange(1)}>
                    Description
                  </button>
                  <button
                    className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
                    onClick={() => handleTabChange(2)}>
                    Reviews
                  </button>
                  <button
                    className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
                    onClick={() => handleTabChange(3)}>
                    Wishlist
                  </button>
                  <button
                    className={activeTab === 4 ? "tab-btn active" : "tab-btn"}
                    onClick={() => handleTabChange(4)}>
                    Sign Out
                  </button>
                </div>

                <br />
                <br />
                <div className="tab-content">
                  {activeTab === 1 && (
                    <>
                      <div className="profile-ips">
                        <div className="profile-ip">
                          <label>Full Name</label>
                          <p>{profileData?.name || "Alexa Rawles"}</p>
                          {/* <input placeholder="Your First Name" /> */}
                        </div>
                        <div className="profile-ip">
                          <label>Email</label>
                          <p>{profileData?.email || ""}</p>
                          {/* <input placeholder="Your First Name" /> */}
                        </div>
                      </div>
                      <br />

                      <div className="profile-ips">
                        <div className="profile-ip">
                          <label>Mobile No.</label>
                          <p>{profileData?.mobile || ""}</p>
                          {/* <input placeholder="Your First Name" /> */}
                        </div>
                        {/* <div className="profile-ip">
                          <label>Full Name</label>
                          <input placeholder="Your First Name" />
                        </div> */}
                      </div>
                      <br />

                      {/* <div className="profile-ips">
                        <div className="profile-ip">
                          <label>Full Name</label>
                          <input placeholder="Your First Name" />
                        </div>
                        <div className="profile-ip">
                          <label>Full Name</label>
                          <input placeholder="Your First Name" />
                        </div>
                      </div> */}
                      <br />
                      <br />
                      <div className="mails">
                        <h3>My email Address</h3>
                        <br />
                        <h4>{profileData?.email || ""}</h4>
                        <p>1 month ago</p>
                      </div>
                      <br />
                      <br />

                      <div className="btns">
                        <button className="addmail">+Add Email Address</button>
                        <button className="SaveChanges">Save Changes</button>
                      </div>
                    </>
                  )}
                  {activeTab === 2 && (
                    <>
                      <div className="newover">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Order ID</th>
                              <th scope="col">Status</th>
                              <th scope="col">Date</th>
                              <th scope="col">Total</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">#96459761</th>
                              <td>IN PROGRESS</td>
                              <td>Dec 30, 2019 07:52</td>
                              <td>$80 (5 Products)</td>
                              <td>View Details</td>
                            </tr>
                            <tr>
                              <th scope="row">#96459761</th>
                              <td>IN PROGRESS</td>
                              <td>Dec 30, 2019 07:52</td>
                              <td>$80 (5 Products)</td>
                              <td>View Details</td>
                            </tr>
                            <tr>
                              <th scope="row">#96459761</th>
                              <td>IN PROGRESS</td>
                              <td>Dec 30, 2019 07:52</td>
                              <td>$80 (5 Products)</td>
                              <td>View Details</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {activeTab === 3 && (
                    <>
                      <div className="shipping-cards">
                        <div>
                          <div className="shipping-card">
                            <img src={img3} />
                            <br />
                            <br />
                            <h3>Domestic shipping</h3>
                            <p>
                              Manage all channels in a single view and reach
                              nationwide efficiently with AI- based courier
                              selection
                            </p>
                            <br />
                            <br />
                            <div className="product-prise">
                              <p>
                                <del>INR7.00</del> INR5.00
                              </p>
                              <img src={cart} />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="shipping-card">
                            <img src={img3} />
                            <br />
                            <br />
                            <h3>Domestic shipping</h3>
                            <p>
                              Manage all channels in a single view and reach
                              nationwide efficiently with AI- based courier
                              selection
                            </p>
                            <br />
                            <br />
                            <div className="product-prise">
                              <p>
                                <del>INR7.00</del> INR5.00
                              </p>
                              <img src={cart} />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="shipping-card">
                            <img src={img3} />
                            <br />
                            <br />
                            <h3>Domestic shipping</h3>
                            <p>
                              Manage all channels in a single view and reach
                              nationwide efficiently with AI- based courier
                              selection
                            </p>
                            <br />
                            <br />
                            <div className="product-prise">
                              <p>
                                <del>INR7.00</del> INR5.00
                              </p>
                              <img src={cart} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
