import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import back from '../Assets/back-svgrepo-com 1.png'
import icon1 from '../Assets/Featured icon.png'
import icon2 from '../Assets/Chart mini.png'
import AdminNavbar from '../Components/AdminNavbar'
function Customers() {
    const data = [
        {
          Name: 'Jones Bonn',
          Email: 'JonesBonn@gmail.com ',
          Location: 'Delhi',
          Purchase: '$150',
          LifeTime: '$14.88',

          id: 1,
        //   img: 'https://via.placeholder.com/50',
        //   ProductName: 'Xiaomi Wifi Repeater Pro',
        //   SKU: 'TR-001',
        //   Category:'Electronics',
        //   Payment: '$600 Fully Paid',
          // Price: '$254.00',
          
          // Type: 'Simple',
          // Statistics: 'Best Seller',
          // tag: 'Top Rated Best Seller, phone',
    
    
          // Rate: '*',
          // Date: 'Last Modifies:23/03/2024',
          Actions: '',
    
    
    
    
    
        },
    
      ];
  return (
  
       
        <>
        <div className='home'>
      <AdminNavbar/>

        <div className='home-padding'>

        <div className='add-heading'>
    <img src={back}/>
    <h3>Customers</h3>

    </div>
    <br/>

<div className='allproduct-color'>
<h3>Customers List</h3>
<p>Date</p>
<br/>
<div className='allproduct-inps'>
<div>

    <select id="fruits" name="fruits">
        <option value="apple">Apple</option>
        <option value="banana">Banana</option>
        <option value="orange">Orange</option>
        <option value="mango">Mango</option>
    </select>
</div>
<div>

    <select id="fruits" name="fruits">
        <option value="apple">Apple</option>
        <option value="banana">Banana</option>
        <option value="orange">Orange</option>
        <option value="mango">Mango</option>
    </select>
</div>
   
 



</div>
<br/>
<br/>





<div className="newover">
     
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr className='table-headings'>
           
            <th>Name</th>

            <th>Email</th>
            <th>Location</th>
            <th>Purchase</th>
            
            <th>Life Time</th>
            <th>Actions</th>

          


            
           
         


          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
            <td>{item.Name}</td>

              {/* <td>
                <img
                  src={item.img}
                  alt={item.ProductName}
                  className="img-fluid rounded"
                  style={{ width: '50px', height: '50px' }}
                />
              </td> */}
              <td>{item.Email}</td>
              <td>{item.Location}</td>
              <td>{item.Purchase}</td>
             
              <td>{item.LifeTime}</td>

              
              <td></td>



            


            </tr>
          ))}
        </tbody>
      </table>
    </div>
</div>

    



    </div>


       

    
        <Footer/>

        </div>
    </>
  )
}

export default Customers