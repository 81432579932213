import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import apiRequest from '../../common/apiRequest';
import { showErrorToast } from '../../common/toast';
import Loader from '../../loader/Loader';
import AdminNavbar from "../../Components/AdminNavbar";

const BaseProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch product details from the API
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(`/product/single/${id}`, 'GET');
        setProduct(response?.data);
      } catch (error) {
        console.error('Error fetching product:', error);
        showErrorToast('Failed to load product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  return (
    <>
      <div className="home">
        <AdminNavbar />
        <div className="path">
          <p>
            Home {">"} Products {">"} <span>{product?.productName || 'Product'}</span>
          </p>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="ProductDetail">
            <div className="ProductDetail-left">
              <div className="side-img-div">
                {product?.images && Object.values(product.images).map((image, index) => (
                  <div className="side-img" key={index}>
                    <img src={image} alt={`Product image ${index + 1}`} />
                  </div>
                ))}
              </div>
              <div className="main-img">
                <img src={product?.images?.image1} alt="Main product" />
              </div>
            </div>
            <div className="ProductDetail-right">
              <h2>{product?.productName}</h2>
              <h3>
                INR{product?.salePrice}<del>INR{product?.originalPrice || '0'}</del>
              </h3>
              <br />
              <p>
                {product?.description || 'No description available.'}
              </p>
              <br />
              <h4>Select Colors</h4>
              <br />
              <div className="color-div">
                {/* Add color options here */}
              </div>
              <br />
              <h4>Choose Size</h4>
              <br />
              <div className="size-div">
                {/* Add size options here */}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default BaseProductDetail;
