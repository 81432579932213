import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img from "../Assets/image 152.png";
import trash from "../Assets/trash.png";
import apiRequest from "../common/apiRequest";
import Loader from "../loader/Loader";
function Cart() {
  const [cart, setCart] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchCart = async () => {
    setLoading(true);
    try {
      const response = await apiRequest("/cart/self", "GET");
      if (response.data) {
        setCart(response.data); // Save the cart data in state
      }
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchCart();
  }, []);

  const updateQuantity = async (productId, productType, newQuantity) => {
    setLoading(true);
    try {
      const response = await apiRequest("/cart/modify", "POST", {
        productId,
        quantity: newQuantity,
        productType,
        actionType: "update"
      });
      if (response.data) {
        fetchCart();
      }
    } catch (error) {
      console.error("Error updating cart quantity:", error);
    }
    setLoading(false);
  };

  const removeItem = async (productId, productType) => {
    setLoading(true);
    try {
      const response = await apiRequest("/cart/modify", "POST", {
        productId,
        productType,
        actionType: "remove",
        quantity:1
      });
      if (response.data) {
        fetchCart();
      }
    } catch (error) {
      console.error("Error removing item:", error);
    }
    setLoading(false);
  };

  const handleDecrement = (productId, productType, quantity) => {
    if (quantity > 1) {
      updateQuantity(productId, productType, quantity - 1);
    }
  };

  const handleIncrement = (productId, productType, quantity) => {
    if (quantity < 100) {
      updateQuantity(productId, productType, quantity + 1);
    }
  };

  const handleChange = (e, productId, productType) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1 && value <= 100) {
      updateQuantity(productId, productType, value);
    }
  };
  return (
    <>
      <div className="home">
        <Navbar />
        {loading && <Loader />}
        <div className="Wishlist">
          <h6>
            Home <span>{">"} Cart</span>
          </h6>
          <h2>My cart</h2>
        </div>
        <div className="cart">
          <div className="cart-left">
            {cart?.products?.length > 0 && (
              <div className="cart-list">
                {cart.products.map((product) => (
                  <div key={product._id} className="cart-item">
                    <div className="cart-text">
                      <img
                        src={product?.productId?.images?.image1}
                        alt={product?.productId?.productName}
                        height={"125pox"}
                        width={"125px"}
                      />
                      <div className="cart-text2">
                        <h3>{product?.productId?.productName}</h3>
                        <p>
                          Price: <span>${product?.productId?.salePrice}</span>
                        </p>
                        {/* <p>
                          Stock: <span>{product?.productId?.quantityInStock}</span>
                        </p> */}
                      </div>
                    </div>
                    <div className="cart-icon">
                      <img
                        src={trash}
                        alt="Remove item"
                        onClick={() =>
                          removeItem(product?.productId?._id, "Product")
                        }
                      />
                      <br />
                      <div className="quantity-input">
                        <button
                          className="decrement"
                          onClick={() =>
                            handleDecrement(
                              product?.productId?._id,
                              "Product",
                              product?.quantity
                            )
                          }>
                          -
                        </button>
                        <input
                          type="number"
                          className="input-field"
                          value={product.quantity}
                          min="1"
                          max="100"
                          onChange={(e) =>
                            handleChange(e, product?.productId?._id, "Product")
                          }
                        />
                        <button
                          className="increment"
                          onClick={() =>
                            handleIncrement(
                              product?.productId?._id,
                              "Product",
                              product?.quantity
                            )
                          }>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {cart?.softProducts?.length > 0 && (
              <div className="cart-list">
                {cart.softProducts.map((product) => (
                  <div key={product._id} className="cart-item">
                    <div className="cart-text">
                      <img
                        src={product?.productId?.productImage}
                        alt={product?.productId?.productName}
                        height={"125pox"}
                        width={"125px"}
                      />
                      <div className="cart-text2">
                        <h3>{product?.productId?.productName}</h3>
                        <p>
                          Price: <span>${product?.productId?.price}</span>
                        </p>
                        <p>
                          Type: <span>{product?.productId?.productType}</span>
                        </p>
                      </div>
                    </div>
                    <div className="cart-icon">
                      <img
                        src={trash}
                        alt="Remove item"
                        onClick={() => removeItem(product?.productId?._id, "SoftProduct")}
                      />
                      <br />
                      <div className="quantity-input">
                        <button
                          className="decrement"
                          onClick={() =>
                            handleDecrement(
                              product?.productId?._id,
                              "SoftProduct",
                              product.quantity
                            )
                          }>
                          -
                        </button>
                        <input
                          type="number"
                          className="input-field"
                          value={product.quantity}
                          min="1"
                          max="100"
                          onChange={(e) =>
                            handleChange(e, product?.productId?._id, "SoftProduct")
                          }
                        />
                        <button
                          className="increment"
                          onClick={() =>
                            handleIncrement(
                              product?.productId?._id,
                              "SoftProduct",
                              product.quantity
                            )
                          }>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* <div className="cart-list">
              <div className="cart-item">
                <div className="cart-text">
                  <img src={img} />
                  <div className="cart-text2">
                    <h3>New Tablet Phone</h3>
                    <p>
                      Size: <span>Large</span>
                    </p>
                    <p>
                      Color: <span> White</span>{" "}
                    </p>

                    <h5>$260</h5>
                  </div>
                </div>
                <div className="cart-icon">
                  <img src={trash} />
                  <br />
                  <br />
                  <div className="quantity-input">
                    <button className="decrement" onClick={handleDecrement}>
                      -
                    </button>
                    <input
                      type="number"
                      className="input-field"
                      value={quantity}
                      min="1"
                      max="100"
                      onChange={handleChange}
                    />
                    <button className="increment" onClick={handleIncrement}>
                      +
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />

              <div className="cart-item">
                <div className="cart-text">
                  <img src={img} />
                  <div className="cart-text2">
                    <h3>New Tablet Phone</h3>
                    <p>
                      Size: <span>Large</span>
                    </p>
                    <p>
                      Color: <span> White</span>{" "}
                    </p>

                    <h5>$260</h5>
                  </div>
                </div>
                <div className="cart-icon">
                  <img src={trash} />
                  <br />
                  <br />
                  <div className="quantity-input">
                    <button className="decrement" onClick={handleDecrement}>
                      -
                    </button>
                    <input
                      type="number"
                      className="input-field"
                      value={quantity}
                      min="1"
                      max="100"
                      onChange={handleChange}
                    />
                    <button className="increment" onClick={handleIncrement}>
                      +
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />

              <div className="cart-item">
                <div className="cart-text">
                  <img src={img} />
                  <div className="cart-text2">
                    <h3>New Tablet Phone</h3>
                    <p>
                      Size: <span>Large</span>
                    </p>
                    <p>
                      Color: <span> White</span>{" "}
                    </p>

                    <h5>$260</h5>
                  </div>
                </div>
                <div className="cart-icon">
                  <img src={trash} />
                  <br />
                  <br />
                  <div className="quantity-input">
                    <button className="decrement" onClick={handleDecrement}>
                      -
                    </button>
                    <input
                      type="number"
                      className="input-field"
                      value={quantity}
                      min="1"
                      max="100"
                      onChange={handleChange}
                    />
                    <button className="increment" onClick={handleIncrement}>
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="cart-right">
            <div className="cart-prices">
              <h2>Order Summary</h2>
              <br />
              <div className="cart-Subtotal">
                <h3>Subtotal</h3>
                <h4>${cart?.totalAmount}</h4>
              </div>
              <br />

              <div className="cart-Subtotal">
                <h3>Discount (-20%)</h3>
                <h4 style={{ color: "rgba(255, 51, 51, 1)" }}>-$113</h4>
              </div>
              <br />
              <div className="cart-Subtotal">
                <h3>Subtotal</h3>
                <h4>${cart?.totalAmount}</h4>
              </div>
              <br />
              <div className="line5"></div>
              <br />

              <div className="promocode">
                <input placeholder="Add Promo Code" />
                <button>Apply</button>
              </div>
              <br />
              <button className="GotoCheckout">Go to Checkout</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Cart;
