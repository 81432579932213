import React, { useEffect, useState } from "react";
import img3 from "../../Assets/image 146.png";
import cart from "../../Assets/cart.png";
import apiRequest from "../../common/apiRequest";
import { showErrorToast, showSuccessToast } from "../../common/toast";
import Loader from "../../loader/Loader";
import { useNavigate } from "react-router-dom";

const ListProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]); // State to hold the list of products
  const [loading, setLoading] = useState(true); // Loader state
  const [cartUpdating, setCartUpdating] = useState(false);

  // Fetch products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await apiRequest("/product/all", "GET"); // API request using the utility
        setProducts(response?.data || []); // Assuming the response contains a 'products' field
        // showSuccessToast('Products loaded successfully'); // Success toast
      } catch (error) {
        console.error("Error fetching products:", error);
        // showErrorToast('Failed to load products'); // Error toast
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Loader display
  const handleAddToCart = async (
    productId,
  ) => {
    setCartUpdating(true); // Start updating state
    try {
      const requestBody = {
        productId: productId,
        quantity: 1,
        productType: "Product",
        actionType:"add"
      };

      const response = await apiRequest("/cart/modify", "POST", requestBody);

      if (response?.data) {
        showSuccessToast("Product added to cart");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
      showErrorToast("Error adding product to cart");
    } finally {
      setCartUpdating(false); // End updating state
    }
  };

  return (
    <div className="products-div">
      <div className="products-div-text">
        {loading && <Loader />}
        <h3>Products</h3>
        {/* <p>
          Showing {products?.length || 0} Products Sort by:{" "}
          <span>Most Popular</span>
        </p> */}
      </div>
      <br />
      {/* <div className="fliter">
        <p>Filters</p>
      </div> */}
      <br />
      <br />

      {/* Products Display */}
      <div className="shipping-cards">
        {products.map((product, index) => (
          <div key={index}>
            <div className="shipping-card shipping-card2">
              <img
                src={product?.images?.image1 || img3}
                alt={product.name}
                onClick={() => navigate(`/productdetail/${product?._id}`)}
                style={{cursor:"pointer"}}
              />{" "}
              {/* Default image fallback */}
              <br />
              <br />
             <div className="p-text">
             <h3>{product?.productName}</h3>
              <p>{product?.description || ""}</p>
              <br />
             
              <div className="product-prise">
                <p>
                  <del>INR{product.originalPrice}</del>
                  {product.salePrice}
                </p>
                <img
                  src={cart}
                  alt="Add to Cart"
                  onClick={() =>
                    handleAddToCart(product._id, "Product", "add", 1)
                  }
                />
              </div>
             </div>
            </div>
          </div>
        ))}
      </div>
      {/* <br />
      <br /> */}

      <div className="center">
        <button className="allpdt-btn">Stay Tight</button>
      </div>
    </div>
  );
};

export default ListProduct;
