import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function ComingSoon() {
  return (

  <>
    <Navbar/>
    <div className='home2'>
    <h1>Coming<br/> Soon</h1>
      
      </div>
    <Footer/>
  </>
  )
}

export default ComingSoon