import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import back from "../Assets/back-svgrepo-com 1.png";
import AdminNavbar from "../Components/AdminNavbar";
import ProductTable from "./ProductTable";
import SoftProductTable from "./SoftProductTable";

function AllProduct() {
  const [activeTab, setActiveTab] = useState("product");

  // const data = [
  //     {
  //       id: 1,
  //       img: 'https://via.placeholder.com/50',
  //       ProductName: 'Xiaomi Wifi Repeater Pro',
  //       SKU: 'TR-001',
  //       Stock: 'In Stock (120)',
  //       Price: '$254.00',

  //       Type: 'Simple',
  //       Statistics: 'Best Seller',
  //       tag: 'Top Rated Best Seller, phone',

  //       Rate: '*',
  //       Date: 'Last Modifies:23/03/2024',
  //       Actions: '',

  //     },

  //   ];

  return (
    <>
      <div className="home">
        <AdminNavbar />
    <div className='home-board-padding'>

        <div className="home-padding home-padding11">
          <div className="add-heading">
            <img src={back} />
            <h3>All Products</h3>
          </div>
          <div className="toggle-buttons mt-4">
  <button 
    className={`btn ${activeTab === 'product' ? 'btn-primary' : 'btn-secondary'}`} 
    style={{
      backgroundColor: activeTab === 'product' ? 'rgba(115, 90, 229, 1)' : 'white',
      color: activeTab === 'product' ? 'white' : 'rgba(115, 90, 229, 1)',
      borderColor: 'rgba(115, 90, 229, 1)'
    }}
    onClick={() => setActiveTab('product')}
  >
    Product
  </button> &nbsp; &nbsp;
  <button 
    className={`btn ${activeTab === 'softproduct' ? 'btn-primary' : 'btn-secondary'}`} 
    style={{
      backgroundColor: activeTab === 'softproduct' ? 'rgba(115, 90, 229, 1)' : 'white',
      color: activeTab === 'softproduct' ? 'white' : 'rgba(115, 90, 229, 1)',
      borderColor: 'rgba(115, 90, 229, 1)'
    }}
    onClick={() => setActiveTab('softproduct')}
  >
    Soft Product
  </button>
</div>
<br/>

          {/* Conditionally render components */}
          {activeTab === 'product' ? <ProductTable /> : <SoftProductTable />}

          {/* <div className='allproduct-color'>
<h3>Products</h3>
<p>Products: All (16) | Publishes (7)  |  Drafts (5)  |  Trash (4)</p>
<br/>
<div className='allproduct-inps'>
<div>

    <select id="fruits" name="fruits">
        <option value="apple">Apple</option>
        <option value="banana">Banana</option>
        <option value="orange">Orange</option>
        <option value="mango">Mango</option>
    </select>
</div>

<div>

    <select id="fruits" name="fruits">
        <option value="apple">Apple</option>
        <option value="banana">Banana</option>
        <option value="orange">Orange</option>
        <option value="mango">Mango</option>
    </select>
</div>
<div>

<select id="fruits" name="fruits">
    <option value="apple">Apple</option>
    <option value="banana">Banana</option>
    <option value="orange">Orange</option>
    <option value="mango">Mango</option>
</select>
</div>
<div>

<select id="fruits" name="fruits">
    <option value="apple">Apple</option>
    <option value="banana">Banana</option>
    <option value="orange">Orange</option>
    <option value="mango">Mango</option>
</select>
</div>
<div>

<select id="fruits" name="fruits">
    <option value="apple">Apple</option>
    <option value="banana">Banana</option>
    <option value="orange">Orange</option>
    <option value="mango">Mango</option>
</select>
</div>




</div>
<br/>
<div className='allproduct-inps'>
<button>Apply</button>
<button>Clear</button>
</div>
<br/>

<div className="newover">
     
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr className='table-headings'>
            <th><input type='checkbox' /></th>
            <th>Image</th>
            <th>Product Name</th>
            <th>SKU</th>
            <th>Stock</th>
            <th>Price</th>
            <th>Type</th>
            <th>Statistics</th>
            <th>Tags</th>
            <th>Rate</th>
            <th>Date</th>
            <th>Actions</th>


          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td><input type='checkbox' /></td>
              <td>
                <img
                  src={item.img}
                  alt={item.ProductName}
                  className="img-fluid rounded"
                  style={{ width: '50px', height: '50px' }}
                />
              </td>
              <td>{item.ProductName}</td>
              <td>{item.SKU}</td>
              <td>{item.Stock}</td>
              <td>{item.Price}</td>
              <td>{item.Type}</td>
              <td>{item.Statistics}</td>
              <td>{item.tag}</td>
              <td>{item.Rate}</td>
              <td>{item.Date}</td>
              <td>{item.Actions}</td>


            </tr>
          ))}
        </tbody>
      </table>
    </div>
</div> */}
        </div>
        </div>


        <Footer />
      </div>
    </>
  );
}

export default AllProduct;
