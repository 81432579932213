import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import apiRequest from '../common/apiRequest'; 
import { showErrorToast, showSuccessToast } from '../common/toast'; 
import Loader from '../loader/Loader'; 
import cartIcon from "../Assets/cart.png"; 
import wishlistIcon from "../Assets/cart.png"; // Add an icon for the wishlist

function SoftProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null); 
  const [loading, setLoading] = useState(true); // Loader state
  const [cartUpdating, setCartUpdating] = useState(false); // State for cart updating
  const [wishlistUpdating, setWishlistUpdating] = useState(false); // State for wishlist updating

  // Fetch product details from the API
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(`/softproduct/single/${id}`, 'GET'); // Fetching soft product details
        setProduct(response?.data); // Assuming the response contains the product data
      } catch (error) {
        console.error('Error fetching product:', error);
        showErrorToast('Failed to load product details'); // Show error toast
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  // Add to Cart function
  const handleAddToCart = async () => {
    setCartUpdating(true); // Start updating state
    try {
      const requestBody = {
        productId: id, 
        quantity: 1,            
        productType: "SoftProduct", // Set product type as SoftProduct
        actionType: "add"  
      };

      const response = await apiRequest('/cart/modify', 'POST', requestBody);

      if (response?.data) {
        showSuccessToast('Product added to cart');
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
      showErrorToast('Error adding product to cart');
    } finally {
      setCartUpdating(false); // End updating state
    }
  };

  // Add to Wishlist function
  const handleAddToWishlist = async () => {
    setWishlistUpdating(true); // Start updating wishlist state
    try {
      const requestBody = {
        productId: id,
        productType: "SoftProduct",
        actionType:"add"
      };

      const response = await apiRequest('/wishlist/modify', 'POST', requestBody);

      if (response?.data) {
        showSuccessToast('Product added to wishlist');
      }
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      showErrorToast('Error adding product to wishlist');
    } finally {
      setWishlistUpdating(false); // End updating wishlist state
    }
  };

  return (
    <>
      <div className="home">
        <Navbar />
        <div className="path">
          <p>
            Home {">"} Soft Products {">"} <span>{product?.productName || 'Product'}</span>
          </p>
        </div>
        {loading ? (
          <Loader /> // Show loader while fetching
        ) : (
          <div className="ProductDetail">
            <div className="ProductDetail-left">
              <div className="main-img">
                <img src={product?.productImage} alt="Main product" /> {/* Display product image */}
              </div>
            </div>
            <div className="ProductDetail-right">
              <h2>{product?.productName}</h2>
              <h3>INR{product?.price}</h3>
              <p>Stage: {product?.stage}</p>
              <p>Product Type: {product?.productType}</p>
              <p>Seller: {product?.sellerId?.name} ({product?.sellerId?.email})</p>
              <p>Software Version: {product?.softwareVersion}</p>
              <p>GitHub Repository: <a href={product?.gitRepoLink} target="_blank" rel="noopener noreferrer">{product?.gitRepoLink}</a></p>
              
              <div className="button-group">
                <button
                  className="add-to-cart-btn"
                  onClick={handleAddToCart}
                  disabled={cartUpdating} // Disable button while updating cart
                >
                  <img src={cartIcon} alt="Add to Cart" />
                  {cartUpdating ? 'Adding to Cart...' : 'Add to Cart'}
                </button>
                
                <button
                  className="add-to-wishlist-btn"
                  onClick={handleAddToWishlist}
                  disabled={wishlistUpdating} // Disable button while updating wishlist
                >
                  <img src={wishlistIcon} alt="Add to Wishlist" />
                  {wishlistUpdating ? 'Adding to Wishlist...' : 'Add to Wishlist'}
                </button>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default SoftProductDetail;
