import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Terms() {
  return (
    <>
      <div className="home">
        <Navbar />
        <div className='privacy'>
        <h1>Terms and Conditions for BuyMyProject</h1>
   <br/>


<p>Welcome to BuyMyProject, a platform operated by Bong Innovations (OPC) Private Limited. By accessing or using our website (<a href="http://buymyproject.in">http://buymyproject.in</a>), you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use our services.</p>

<h2>1. User Eligibility</h2>
<ul>
    <li>By registering on BuyMyProject, you confirm that you are at least 18 years old, or that you have parental or legal guardian consent to use the platform. We reserve the right to revoke access to any user who fails to comply with this requirement.</li>
</ul>

<h2>2. Account Responsibilities</h2>
<ul>
    <li>Users are responsible for maintaining the confidentiality of their account and password and for restricting access to their computer. All activities conducted under your account are your responsibility.</li>
</ul>

<h2>3. Product Listings and Sales</h2>
<ul>
    <li>BuyMyProject acts as an intermediary to facilitate the sale of student projects. We do not own the projects listed by users, and responsibility for the accuracy and legality of the project information rests with the user posting the listing.</li>
    <li>You agree to provide accurate and complete information regarding the project or product listed for sale. Any misrepresentation of products or services may lead to removal from the platform and potential legal action.</li>
</ul>

<h2>4. Payments and Fees</h2>
<ul>
    <li>All prices and fees on the platform are denominated in Indian Rupees (INR). Users are responsible for paying all applicable taxes and service fees associated with purchases or listings on BuyMyProject.</li>
    <li>We reserve the right to modify fees at any time, and users will be informed in advance of any changes.</li>
</ul>

<h2>5. Returns and Refunds</h2>
<ul>
    <li>As a marketplace, BuyMyProject does not directly handle returns or refunds. Sellers must provide a clear returns policy on their listings, and buyers should be aware of these terms before making a purchase. BuyMyProject may assist in resolving disputes between buyers and sellers, but final decisions lie with the involved parties.</li>
</ul>

<h2>6. Intellectual Property Rights</h2>
<ul>
    <li>All content available on the platform, including text, images, videos, and other materials, are protected by copyright laws. Users may not use, copy, or reproduce content without proper authorization from the respective rights holders.</li>
</ul>

<h2>7. Liability Limitations</h2>
<ul>
    <li>BuyMyProject provides the platform “as is” and does not guarantee the quality or performance of any project sold. We are not liable for any damages, losses, or disputes arising from transactions conducted through the platform.</li>
</ul>

<h2>8. Termination</h2>
<ul>
    <li>We reserve the right to suspend or terminate accounts for violations of our terms or any suspicious activity. Users may also request account termination by contacting our support team at <a href="mailto:ceo@bonginnovations.io">ceo@bonginnovations.io</a>.</li>
</ul>

<h2>9. Amendments</h2>
<ul>
    <li>These terms may be amended at any time by BuyMyProject. Continued use of the platform following such amendments will constitute acceptance of the modified terms.</li>
</ul>

<h2>10. Governing Law</h2>
<ul>
    <li>These terms are governed by the laws of India, and any legal disputes will be subject to the exclusive jurisdiction of courts located in Kolkata, West Bengal.</li>
</ul>
      
    
        </div>
        <Footer/>

        </div>
    </>
  )
}

export default Terms