import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import icon from '../Assets/Vector.png';
import icon2 from '../Assets/Icon.png';
import { FaUser } from 'react-icons/fa';
import logo from '../Assets/bong.png'
function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [user, setUser] = useState(null); // State to hold user data
  const [isHovered, setIsHovered] = useState(false); // State to handle hover
  const toggleDivRef = useRef(null);

  useEffect(() => {
    // Check localStorage for bong_user and bong_user_token
    const bongUser = localStorage.getItem('bong_user');
    const bongUserToken = localStorage.getItem('bong_user_token');

    if (bongUser && bongUserToken) {
      setUser(JSON.parse(bongUser)); // Parse and set the user data
    }
  }, []);

  // Function to open the toggle menu
  const openToggleDiv = () => {
    setIsDivVisible(true);
  };

  // Function to close the toggle menu
  const closeToggleDiv = () => {
    setIsDivVisible(false);
  };

  // Function to handle click outside of the toggle div
  const handleClickOutside = (event) => {
    if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
      closeToggleDiv(); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    if (isDivVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDivVisible]);

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('bong_user');
    localStorage.removeItem('bong_user_token');
    setUser(null); // Reset the user state after logout
  };

  return (
    <>
      <nav>
      <Link to='/'>

       <img className='navlogo' src={logo}/>
      </Link>
        <ul>
          <NavLink exact className='none2' activeClassName="active" to='/'>
            <li>Home</li>
          </NavLink>

          <NavLink exact className='none2' activeClassName="active" to='/about'>
            <li>About</li>
          </NavLink>
          <div class="dropdown">
          <li>Catagories</li>


  <div class="dropdown-content dropdown-content2">

  <NavLink className='none2' activeClassName="active" to='/allproduct'>
            <li>Products</li>
          </NavLink>
          <NavLink className='none2' activeClassName="active" to='/softproduct'>
            <li>Software</li>
          </NavLink>

  </div>
</div>
          
          {/* <NavLink className='none2' activeClassName="active" to='/allproduct'>
            <li>Products</li>
          </NavLink>
          <NavLink className='none2' activeClassName="active" to='/softproduct'>
            <li>Software</li>
          </NavLink> */}
          <NavLink className='none2' activeClassName="active" to='/allproduct'>
            <li>Marketplace</li>
          </NavLink>
          <NavLink className='none2' activeClassName="active" to='/blog'>
            <li>Blog</li>
          </NavLink>
          <NavLink className='none2' activeClassName="active" to='/advertisement'>
            <li>Advertisement</li>
          </NavLink>
        </ul>

        <ul className='nav-btns'>
          <li>
            <img src={icon} alt="icon" />
          </li>
          <NavLink className='none2' activeClassName="active" to='/advertisement'>
            <li>
              <button className='nav-cart'>
                <img src={icon2} alt="cart icon" />0
              </button>
            </li>
          </NavLink>

          {/* Conditionally render login button or user name */}
          {user ? (
    <>
    <div class="dropdown">
    <FaUser className='profile-icon' /> 

  <div class="dropdown-content">
  <div className='dp-name'>
  <span className='user-details'>
          Welcome, {user.name}
        </span>
        <button className='nav-logout' onClick={handleLogout}>
          Logout
        </button>
  </div>
  </div>
</div>

    <div>
    </div>

    </>

): (
            <NavLink className='none2' activeClassName="active" to='/login'>
              <li>
                <button className='nav-login'>Login</button>
              </li>
            </NavLink>
          )}
        </ul>

        <i
          style={{ color: 'black', cursor: 'pointer' }}
          className="bi bi-list"
          onClick={isDivVisible ? closeToggleDiv : openToggleDiv} // Conditional onClick
        ></i>
      </nav>

      {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
              <NavLink exact className="none" activeClassName="active" to="/">
                <li>Home</li>
              </NavLink>
              <NavLink exact className='none' activeClassName="active" to='/about'>
            <li>About</li>
          </NavLink>
          <div class="dropdown">
          <li>Catagories</li>


  <div class="dropdown-content dropdown-content2">

  <NavLink style={{color:'black'}} className='none' activeClassName="active" to='/allproduct'>
            <li>Products</li>
          </NavLink>
          <NavLink style={{color:'black'}} className='none' activeClassName="active" to='/softproduct'>
            <li>Software</li>
          </NavLink>

  </div>
</div>
     <NavLink className='none' activeClassName="active" to='/allproduct'>
            <li>Marketplace</li>
          </NavLink>
          <NavLink className='none' activeClassName="active" to='/blog'>
            <li>Blog</li>
          </NavLink>
          <NavLink className='none' activeClassName="active" to='/advertisement'>
            <li>Advertisement</li>
          </NavLink>

              {/* Toggle Div me bhi conditional rendering */}
              {user ? (
                <li>Welcome, {user.name}</li>
              ) : (
                <NavLink className="none" activeClassName="active" to="/login">
                  <li>Login</li>
                </NavLink>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
