import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import back from "../Assets/back-svgrepo-com 1.png";
import icon1 from "../Assets/Featured icon.png";
import icon2 from "../Assets/Chart mini.png";
import AdminNavbar from "../Components/AdminNavbar";
import axios from "axios";
import sellerapiRequest from "../common/sellerapiRequest";
import Loader from "../loader/Loader";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await sellerapiRequest("/order/seller", "GET");
        if (response.data) {
          setOrders(response?.data);
        }
      } catch (error) {}
      setLoading(false);
    };

    fetchOrders();
  }, []);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  return (
    <>
      <div className="home">
        <AdminNavbar />
        <div className="home-board-padding">

        {loading && <Loader />}
        <div className="home-padding home-padding11">
          <div className="add-heading">
            <img src={back} alt="Back" />
            <h3>Orders</h3>
          </div>
          <br />

          <div className="allproduct-color">
            <h3>Orders</h3>
            <p>Sales Period:</p>
            <br />
            {/* <div className="allproduct-inps">
              <div>
                <select id="fruits" name="fruits">
                  <option value="apple">Apple</option>
                  <option value="banana">Banana</option>
                  <option value="orange">Orange</option>
                  <option value="mango">Mango</option>
                </select>
              </div>
              <div>
                <select id="fruits" name="fruits">
                  <option value="apple">Apple</option>
                  <option value="banana">Banana</option>
                  <option value="orange">Orange</option>
                  <option value="mango">Mango</option>
                </select>
              </div>
              <div>
                <select id="fruits" name="fruits">
                  <option value="apple">Apple</option>
                  <option value="banana">Banana</option>
                  <option value="orange">Orange</option>
                  <option value="mango">Mango</option>
                </select>
              </div>
            </div> */}
            {/* <br />
            <br /> */}

            {/* <div className="dash-cards">
              <div className="dash-card">
                <div className="dash-card-heading">
                  <img src={icon1} alt="Icon" />
                  <p>Total Orders</p>
                </div>
                <br />
                <div className="dash-card-text">
                  <div className="dash-card-text-left">
                    <h3>400</h3>
                    <p>10% vs last month</p>
                  </div>
                  <div className="dash-card-text-right">
                    <img src={icon2} alt="Icon" />
                  </div>
                </div>
              </div>
              <div className="dash-card">
                <div className="dash-card-heading">
                  <img src={icon1} alt="Icon" />
                  <p>Total Orders</p>
                </div>
                <br />
                <div className="dash-card-text">
                  <div className="dash-card-text-left">
                    <h3>400</h3>
                    <p>10% vs last month</p>
                  </div>
                  <div className="dash-card-text-right">
                    <img src={icon2} alt="Icon" />
                  </div>
                </div>
              </div>
              <div className="dash-card">
                <div className="dash-card-heading">
                  <img src={icon1} alt="Icon" />
                  <p>Total Orders</p>
                </div>
                <br />
                <div className="dash-card-text">
                  <div className="dash-card-text-left">
                    <h3>400</h3>
                    <p>10% vs last month</p>
                  </div>
                  <div className="dash-card-text-right">
                    <img src={icon2} alt="Icon" />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <br />
            <br /> */}

            <div className="newover">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr className="table-headings">
                    <th>Order</th>
                    <th>Image</th>
                    <th>Product Name</th>
                    {/* <th>SKU</th>
                    <th>Category</th> */}
                    <th>Payment</th>
                    <th>Order Status</th>
                    <th>Rate</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <React.Fragment key={index}>
                      {/* For each product in the order */}
                      {order.products.map((product, prodIndex) => (
                        <tr key={prodIndex}>
                          <td>Product</td>
                          <td>
                            <img
                              src={
                                product?.productDetails?.images?.image1 ||
                                "https://via.placeholder.com/50"
                              }
                              alt={product?.productDetails?.productName}
                              className="img-fluid rounded"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </td>
                          <td>{product?.productDetails?.productName}</td>
                          {/* <td>{product.productDetails.sku}</td> */}
                          {/* <td>{product.productDetails.category}</td> */}
                          <td>{order.paymentMethod}</td>
                          <td>
                            <button className="orderstatus-btn">
                              {order.orderStatus}
                            </button>
                          </td>
                          <td>*</td>
                          <td>Actions</td>
                        </tr>
                      ))}

                      {/* For each softProduct in the order */}
                      {order?.softProducts && order?.softProducts?.map((softProduct, softProdIndex) => (
                        <tr key={softProdIndex}>
                          <td>Project</td>
                          <td>
                            <img
                              src={
                                softProduct?.softProductDetails?.productImage ||
                                "https://via.placeholder.com/50"
                              }
                              alt={softProduct?.softProductDetails?.productName}
                              className="img-fluid rounded"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </td>
                          <td>{softProduct?.softProductDetails?.productName}</td>
                          {/* <td>{softProduct.softProductDetails.sku}</td>
                          <td>{softProduct.softProductDetails.category}</td> */}
                          <td>{order.paymentMethod}</td>
                          <td>
                            <button className="orderstatus-btn">
                              {order.orderStatus}
                            </button>
                          </td>
                          <td>*</td>
                          <td>Actions</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Orders;
