import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import img3 from "../Assets/image 146.png"; // Default image
import cart from "../Assets/cart.png"; // Cart icon
import Loader from "../loader/Loader";
import apiRequest from "../common/apiRequest";
import { showSuccessToast } from "../common/toast";

function Wishlist() {
  const [wishlist, setWishlist] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch wishlist data
  const fetchWishlist = async () => {
    setLoading(true);
    try {
      const response = await apiRequest("/wishlist/self", "GET");
      if (response.data) {
        setWishlist(response.data); // Save the entire wishlist object in state
      }
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
    setLoading(false);
  };

  // Move item to cart
  const moveToCart = async (itemId, productType) => {
    try {
      const payload = {
        productId: itemId,
        productType
      };
      const response = await apiRequest(
        `/wishlist/moveToCart`,
        "POST",
        payload
      );
      if (response.data) {
        showSuccessToast("Item moved to cart successfully");
        // Optionally, refresh the wishlist after moving an item to the cart
        fetchWishlist();
      } else {
        console.error("Error moving item to cart");
      }
    } catch (error) {
      console.error("Error moving item to cart:", error);
    }
  };

  useEffect(() => {
    fetchWishlist(); // Fetch wishlist items when the component mounts
  }, []);

  return (
    <>
      <div className="home">
        <Navbar />
        {loading && <Loader />}
        <div className="Wishlist">
          <h6>
            Home <span>{">"} Wishlist</span>
          </h6>
          <h2>Wishlist</h2>
        </div>
        <div className="Wishlist-div">
          <div className="Wishlist-section">
            {loading ? (
              <p>Loading wishlist...</p>
            ) : wishlist ? (
              <div className="shipping-cards">
                {/* Render Products */}
                {wishlist.products.length > 0 &&
                  wishlist.products.map((product) => (
                    <div key={product._id} className="shipping-card">
                      <img
                        src={product?.productId.images.image1 || img3}
                        alt={product?.productId.productName}
                      />
                      <h3>{product.productId.productName}</h3>
                      <p>Sale Price: INR{product.productId.salePrice}</p>
                      <button
                        onClick={() =>
                          moveToCart(product?.productId?._id, "Product")
                        }>
                        Move to Cart
                      </button>
                    </div>
                  ))}

                {/* Render Soft Products */}
                {wishlist.softProducts.length > 0 &&
                  wishlist.softProducts.map((softProduct) => (
                    <div key={softProduct._id} className="shipping-card">
                      <img
                        src={softProduct?.productId?.productImage || img3}
                        alt={softProduct?.productId?.productName}
                      />
                      <h3>{softProduct?.productId?.productName}</h3>
                      <p>Price: INR{softProduct?.productId.price}</p>
                      <button
                        onClick={() =>
                          moveToCart(softProduct?.productId?._id, "SoftProduct")
                        }>
                        Move to Cart
                      </button>
                    </div>
                  ))}

                {/* If no products */}
                {wishlist.products.length === 0 &&
                  wishlist.softProducts.length === 0 && (
                    <p>Your wishlist is empty.</p>
                  )}
              </div>
            ) : (
              <p>Your wishlist is empty.</p>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Wishlist;
