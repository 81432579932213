import React, { useEffect, useState } from "react";
import img3 from "../../Assets/image 146.png"; // Placeholder image
import apiRequest from "../../common/apiRequest"; // API request utility
import { showErrorToast, showSuccessToast } from "../../common/toast"; // Toast notifications
import Loader from "../../loader/Loader"; // Loader component for loading state
import { useNavigate } from "react-router-dom"; // Navigation hook

const AllBaseProduct = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]); // State to hold the list of products
  const [loading, setLoading] = useState(true); // Loader state

  // Fetch products from the API
  useEffect(() => {
    const fetchBaseProducts = async () => {
      setLoading(true); // Start loading
      try {
        const response = await apiRequest("/product/all", "GET"); // Fetch all base products
        setProducts(response?.data || []); // Assuming response contains products array
        // showSuccessToast("Base Products loaded successfully"); // Success notification
      } catch (error) {
        console.error("Error fetching base products:", error);
        showErrorToast("Failed to load base products"); // Error notification
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchBaseProducts();
  }, []);

  return (
    <div className="products-div">
      <div className="products-div-text">
        {loading && <Loader />} {/* Show loader during loading */}
        <h4>Web Products</h4>
        <p>
          Showing {products?.length || 0} Products
        </p>
      </div>
      <br />

      {/* Products Display */}
      <div className="shipping-cards">
        {products.map((product, index) => (
          <div key={index}>
            <div className="shipping-card">
              <img
                src={product?.images?.image1 || img3} // Display product image or fallback image
                alt={product.productName}
                onClick={() => navigate(`/admin/productdetail/${product?._id}`)} // Navigate to product detail page
                style={{ cursor: "pointer" }}
              />
              <br />
              <br />
              <h3>{product?.productName}</h3>
              <p>{product?.description || ""}</p>
              <br />
              <br />
              <div className="product-prise">
                <p>
                  <del>INR{product.originalPrice}</del> INR{product.salePrice}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />

      {/* <div className="center">
        <button className="allpdt-btn">View More</button>
      </div> */}
    </div>
  );
};

export default AllBaseProduct;
