import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import back from '../Assets/back-svgrepo-com 1.png'
import Footer from "../Components/Footer";
import AdminNavbar from "../Components/AdminNavbar";
import axios from "axios";
import sellerapiRequest from "../common/sellerapiRequest";
import { showSuccessToast } from "../common/toast";
import Loader from "../loader/Loader";

function AddProduct() {
  const [selectedImages, setSelectedImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [quantityInStock, setQuantityInStock] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true)
      try {
        const response = await sellerapiRequest("/category/all", "GET");
        if (response.data) {
          setCategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching categories", error);
      }
      setLoading(false)
    };

    fetchCategories();
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = [...selectedImages, ...files].slice(0, 5); // Limit to 5 images
    setSelectedImages(newImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("category", category);
    formData.append("salePrice", salePrice);
    formData.append("quantityInStock", quantityInStock);
    formData.append("description", description);

    // Append selected images to formData
    selectedImages.forEach((image, index) => {
      formData.append(`image${index + 1}`, image);
    });
    setLoading(true)
    try {
      const response = await sellerapiRequest(
        "/product/create",
        "POST",
        formData
      );
      if (response.data) {
        showSuccessToast("Product Created Successfully.");
      }
    } catch (error) {
      console.error("Error creating product", error);
      alert("Failed to create product");
    }
    setLoading(false)
  };

  return (
    <>
      <div className="home">
        {/* <AdminNavbar />
        <div className="container home-padding"> */}
          {/* <div className="add-heading">
            <img src={back} alt="back" />
            <h3>Add Products</h3>
          </div> */}

          {/* <br /> */}
        <div className="home-board-padding2">
        {loading &&<Loader />}
          <div className="mt-5">
            <h3 className="mb-4">Add New Product</h3>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter product name"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Category</label>
                <select
                  className="form-select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required>
                  <option value="">Select category</option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label className="form-label">Sale Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter sale price"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Quantity in Stock</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter quantity"
                  value={quantityInStock}
                  onChange={(e) => setQuantityInStock(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Product Description</label>
                <textarea
                  className="form-control"
                  placeholder="Enter product description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows="3"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Product Images (Max 5)</label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  className="form-control"
                />
              </div>

              <div className="image-preview-container" style={{display:"flex", gap:"15px"}}>
                {selectedImages.length > 0 &&
                  selectedImages.map((image, index) => (
                    <div key={index} className="image-preview-box" >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index + 1}`}
                        className="image-preview"
                        width={"150px"}
                        height={"150px"}
                      />
                    </div>
                  ))}
              </div>
                <br />

              <div className="d-flex justify-content-between">
                <button type="button" className="savedrf">
                  Save To Draft
                </button>
                <button type="submit" className="publishproduct">
                  Publish Product
                </button>
              </div>
            </form>
          </div>

        </div>

        </div>

        
        <Footer />
      {/* </div> */}
    </>
  );
}

export default AddProduct;
