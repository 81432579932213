import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import sellerapiRequest from "../common/sellerapiRequest";
import { showSuccessToast } from "../common/toast";
import { useParams } from "react-router-dom"; // Assuming you are using react-router
import AdminNavbar from "../Components/AdminNavbar";
import Loader from "../loader/Loader";

function EditProject() {
  const { productId } = useParams(); // Grabbing the product ID from URL for editing
  const [selectedImage, setSelectedImage] = useState(null);
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [price, setPrice] = useState("");
  const [gitRepoLink, setGitRepoLink] = useState("");
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [existingImages, setExistingImages] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch existing product details when component mounts
  useEffect(() => {
    if (productId) {
      fetchProductDetails(productId);
    }
  }, [productId]);

  const fetchProductDetails = async (id) => {
    try {
      setLoading(true);
      const response = await sellerapiRequest(
        `/softproduct/single/${id}`,
        "GET"
      );
      if (response.data) {
        const {
          productName,
          productType,
          price,
          gitRepoLink,
          softwareVersion,
          productImage
        } = response.data;
        setProductName(productName);
        setProductType(productType);
        setPrice(price);
        setGitRepoLink(gitRepoLink);
        setSoftwareVersion(softwareVersion);
        setExistingImages(productImage);
      }
    } catch (error) {
      console.error("Error fetching product details", error);
    }
    setLoading(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productType", productType);
    formData.append("price", price);
    formData.append("gitRepoLink", gitRepoLink);
    formData.append("softwareVersion", softwareVersion);

    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    setLoading(true);
    try {
      const response = await sellerapiRequest(
        `/softproduct/update/${productId}`,
        "PUT",
        formData
      );
      if (response.data) {
        showSuccessToast("Product Updated Successfully.");
        fetchProductDetails(productId);
      }
    } catch (error) {
      console.error("Error updating product", error);
      alert("Failed to update product");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="home">
        <AdminNavbar />
        <div className="container home-padding">
          {loading && <Loader />}
          <h3 className="mb-4">Edit Software Product</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Product Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter product name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Product Type</label>
              <select
                className="form-control"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                required>
                <option value="">Select product type</option>
                <option value="github">GitHub</option>
                <option value="software">Software</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Price</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Git Repo Link</label>
              <input
                type="url"
                className="form-control"
                placeholder="Enter GitHub repository link"
                value={gitRepoLink}
                onChange={(e) => setGitRepoLink(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Software Version</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter software version"
                value={softwareVersion}
                onChange={(e) => setSoftwareVersion(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Existing Product Images</label>
              <div
                className="image-preview-container"
                style={{ display: "flex", gap: "15px" }}>
                {existingImages && (
                  <div className="image-preview-box">
                    <img
                      src={existingImages} // Assuming 'image' is a URL string
                      alt={`Existing Preview`}
                      className="image-preview"
                      width={"150px"}
                      height={"150px"}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Product Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="form-control"
              />
            </div>

            {selectedImage && (
              <div className="image-preview-container">
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Preview"
                  className="image-preview"
                  width="150px"
                  height="150px"
                />
              </div>
            )}

            <br />

            <div className="d-flex justify-content-between">
              <button type="submit" className="btn btn-primary">
                Update Product
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditProject;
