import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import apiRequest from '../common/apiRequest'; 
import { showErrorToast, showSuccessToast } from '../common/toast'; 
import Loader from '../loader/Loader'; 
import cartIcon from "../Assets/cart.png"; 
import wishlistIcon from "../Assets/cart.png"; // Import wishlist icon

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [cartUpdating, setCartUpdating] = useState(false); 
  const [wishlistUpdating, setWishlistUpdating] = useState(false); // Wishlist state

  // Fetch product details from the API
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(`/product/single/${id}`, 'GET');
        setProduct(response?.data); 
      } catch (error) {
        console.error('Error fetching product:', error);
        showErrorToast('Failed to load product details');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  // Add to Cart function
  const handleAddToCart = async () => {
    setCartUpdating(true); 
    try {
      const requestBody = {
        productId: id, 
        quantity: 1,            
        productType: "Product",
        actionType: "add"
      };

      const response = await apiRequest('/cart/modify', 'POST', requestBody);

      if (response?.data) {
        showSuccessToast('Product added to cart');
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
      showErrorToast('Error adding product to cart');
    } finally {
      setCartUpdating(false); 
    }
  };

  // Add to Wishlist function
  const handleAddToWishlist = async () => {
    setWishlistUpdating(true); 
    try {
      const requestBody = {
        productId: id,            
        productType: "Product",
        actionType: "add"
      };

      const response = await apiRequest('/wishlist/modify', 'POST', requestBody);

      if (response?.data) {
        showSuccessToast('Product added to wishlist');
      }
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      showErrorToast('Error adding product to wishlist');
    } finally {
      setWishlistUpdating(false); 
    }
  };

  return (
    <>
      <div className="home">
        <Navbar />
        <div className="path">
          <p>
            Home {">"} Products {">"} <span>{product?.productName || 'Product'}</span>
          </p>
        </div>
        {loading ? (
          <Loader /> 
        ) : (
          <div className="ProductDetail">
            <div className="ProductDetail-left">
              <div className="side-img-div">
                {product?.images && Object.values(product.images).map((image, index) => (
                  <div className="side-img" key={index}>
                    <img src={image} alt={`Product image ${index + 1}`} />
                  </div>
                ))}
              </div>
              <div className="main-img">
                <img src={product?.images?.image1} alt="Main product" />
              </div>
            </div>
            <div className="ProductDetail-right">
              <h2>{product?.productName}</h2>
              <h3>
              INR { product?.salePrice}<del> INR{ product?.originalPrice || ' 0'}</del>
              </h3>
              <br />
              <p>
                {product?.description || 'No description available.'}
              </p>
              <br />
              <h4>Select Colors</h4>
              <br />
              <div className="color-div">
                {/* Add color options here */}
              </div>
              <br />
              <h4>Choose Size</h4>
              <br />
              <div className="size-div">
                {/* Add size options here */}
              </div>
              <br />
             <div className="add-cart-btns">

              <button
                className="add-to-cart-btn"
                onClick={handleAddToCart}
                disabled={cartUpdating} 
              >
                <i class="bi bi-cart"></i>
                {cartUpdating ? 'Adding...' : 'Add to Cart'}
              </button>

              {/* Add to Wishlist Button */}
              <button
                className="add-to-wishlist-btn"
                onClick={handleAddToWishlist}
                disabled={wishlistUpdating} 
              >
              <i class="bi bi-heart"></i>
                {wishlistUpdating ? 'Adding to Wishlist...' : 'Add to Wishlist'}
              </button>
              </div>

            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ProductDetail;
