import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AdminNavbar from "../Components/AdminNavbar";
import sellerapiRequest from "../common/sellerapiRequest";
import { showSuccessToast } from "../common/toast";
import Loader from "../loader/Loader";

function AddProject() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [price, setPrice] = useState("");
  const [gitRepoLink, setGitRepoLink] = useState("");
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Accepting only one image
    setSelectedImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productType", productType);
    formData.append("price", price);
    formData.append("gitRepoLink", gitRepoLink);
    formData.append("softwareVersion", softwareVersion);

    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    setLoading(true)
    try {
      const response = await sellerapiRequest(
        "/softproduct/create",
        "POST",
        formData
      );
      if (response.data) {
        showSuccessToast("Software Product Created Successfully.");
      }
    } catch (error) {
      console.error("Error creating software product", error);
      alert("Failed to create software product");
    }
    setLoading(false)
  };

  return (
    <>
      <div className="home">
      <div className="home-board-padding2">
      {loading && <Loader />}
        <div className="mt-5">
          <h3 className="mb-4">Add New Software Product</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Product Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter product name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Product Type</label>
              <select
                className="form-control"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                required>
                <option value="">Select product type</option>{" "}
                {/* Placeholder option */}
                <option value="github">GitHub</option>
                <option value="software">Software</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Price</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Git Repo Link</label>
              <input
                type="url"
                className="form-control"
                placeholder="Enter GitHub repository link"
                value={gitRepoLink}
                onChange={(e) => setGitRepoLink(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Software Version</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter software version"
                value={softwareVersion}
                onChange={(e) => setSoftwareVersion(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Product Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="form-control"
              />
            </div>

            {selectedImage && (
              <div className="image-preview-container">
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Preview"
                  className="image-preview"
                  width="150px"
                  height="150px"
                />
              </div>
            )}

            <br />

            <div className="d-flex justify-content-between">
              <button type="button" className="savedrf">
                Save To Draft
              </button>
              <button type="submit" className="publishproduct">
                Publish Software Product
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
      <br/>
      <Footer />
    </>
  );
}

export default AddProject;
