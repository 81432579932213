import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Page/Login';
import Signup from './Page/Signup';
import Home from './Page/Home';
import AllProducts from './Page/AllProducts';
import ProductDetail from './Page/ProductDetail';
import Profile from './Page/Profile';
import Wishlist from './Page/Wishlist';
import Cart from './Page/Cart';
import AdminHome from './Admin/AdminHome';
import AddProduct from './Admin/AddProduct';
import AllProduct from './Admin/AllProduct';
import Orders from './Admin/Orders';
import Customers from './Admin/Customers';
import Invoices from './Admin/Invoices';
import Transactions from './Admin/Transactions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SoftProduct from './Page/SoftProduct';
import SoftProductDetail from './Page/SoftProductDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import ParentComponent from './Admin/ParentComponent';
import EditProduct from './Admin/EditProduct';
import EditProject from './Admin/EditProject';
import MainHome from './Page/MainHome';
import AdminPrivateRoute from './router/AdminPrivateRoute';
import ForgotPassword from './Page/ForgotPassword';
import BaseProductDetail from './Admin/product/BaseProductDetail';
import AdminSoftProductDetail from './Admin/product/AdminSoftProductDetail';
import Privacy from './Page/Privacy';
import Terms from './Page/Terms';
import AdminAllProduct from './Admin/AdminAllProduct';
import AboutUs from './Page/AboutUs';
import ComingSoon from './Page/ComingSoon';
import Blog from './Page/Blog';
import NoWorries from './Page/NoWorries';
import Productcomming from './Page/Productcomming';



function App() {
  return (
    <>
      <ToastContainer autoClose={1000} />
      <Router>

        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/allproduct" element={<AllProducts />} />
          <Route path="/softproduct" element={<SoftProduct />} />
          <Route path="/productdetail/:id" element={<ProductDetail />} />
          <Route path="/softproductdetail/:id" element={<SoftProductDetail />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/allproduct" element={<Productcomming />} /> */}

          <Route path="/wishlist2" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/advertisement" element={<ComingSoon />} />
          <Route path="/blog" element={<Blog />} />

          <Route path="/wishlist" element={<ComingSoon />} />
          <Route path="/noworries" element={<NoWorries />} />







          {/* admin */}
          {/*
          <Route path="/dashboard" element={<AdminHome />} />
          <Route path="/addproduct" element={<ParentComponent />} />
          <Route path="/edit-product/:productId" element={<EditProduct />} />
          <Route path="/edit-project/:productId" element={<EditProject />} />
          <Route path="/allproducts" element={<AllProduct />} />
          <Route path="/order" element={<Orders />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/transactions" element={<Transactions />} /> */}
          <Route path="/adminhome" element={<MainHome />} />
          <Route path="/dashboard" element={<AdminPrivateRoute><AdminHome /></AdminPrivateRoute>} />
          <Route path="/webproduct" element={<AdminPrivateRoute><AdminAllProduct /></AdminPrivateRoute>} />
          <Route path="/addproduct" element={<AdminPrivateRoute><ParentComponent /></AdminPrivateRoute>} />
          <Route path="/edit-product/:productId" element={<AdminPrivateRoute><EditProduct /></AdminPrivateRoute>} />
          <Route path="/edit-project/:productId" element={<AdminPrivateRoute><EditProject /></AdminPrivateRoute>} />
          <Route path="/allproducts" element={<AdminPrivateRoute><AllProduct /></AdminPrivateRoute>} />
          <Route path="/order" element={<AdminPrivateRoute><Orders /></AdminPrivateRoute>} />
          <Route path="/customers" element={<AdminPrivateRoute><Customers /></AdminPrivateRoute>} />
          <Route path="/invoices" element={<AdminPrivateRoute><Invoices /></AdminPrivateRoute>} />
          <Route path="/transactions" element={<AdminPrivateRoute><Transactions /></AdminPrivateRoute>} />
          <Route path="/admin/productdetail/:id" element={<AdminPrivateRoute><BaseProductDetail /></AdminPrivateRoute>} />
          <Route path="/admin/softproductdetail/:id" element={<AdminPrivateRoute><AdminSoftProductDetail /></AdminPrivateRoute>}  />

       


















        </Routes>

      </Router>
    </>
  );
}

export default App;
