import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import back from "../Assets/back-svgrepo-com 1.png";
import Footer from "../Components/Footer";
import AdminNavbar from "../Components/AdminNavbar";
import axios from "axios";
import sellerapiRequest from "../common/sellerapiRequest";
import { showSuccessToast } from "../common/toast";
import { useParams } from "react-router-dom"; // To get productId from URL params
import Loader from "../loader/Loader";

function EditProduct() {
  const { productId } = useParams(); // Get the product ID from route
  const [selectedImages, setSelectedImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [quantityInStock, setQuantityInStock] = useState("");
  const [description, setDescription] = useState("");
  const [existingImages, setExistingImages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch product details by productId and categories
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        setLoading(true)
        // Fetch product details by product ID
        const response = await sellerapiRequest(`/product/single/${productId}`, "GET");
        if (response.data) {
          const product = response.data;
          setProductName(product.productName);
          setCategory(product.category);
          setSalePrice(product.salePrice);
          setQuantityInStock(product.quantityInStock);
          setDescription(product.description);
          setExistingImages(Object.values(product.images)); // Assuming the images are in an 'images' field
        }
      } catch (error) {
        console.error("Error fetching product details", error);
      }
      setLoading(false)
    };

    const fetchCategories = async () => {
        setLoading(true)
      try {
        const response = await sellerapiRequest("/category/all", "GET");
        if (response.data) {
          setCategories(response.data);
        }
      } catch (error) {
        console.error("Error fetching categories", error);
      }
      setLoading(false)
    };

    fetchProductDetails();
    fetchCategories();
  }, [productId]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = [...selectedImages, ...files].slice(0, 5); // Limit to 5 images
    setSelectedImages(newImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("category", category);
    formData.append("salePrice", salePrice);
    formData.append("quantityInStock", quantityInStock);
    formData.append("description", description);

    // Append new selected images
    selectedImages.forEach((image, index) => {
      formData.append(`image${index + 1}`, image);
    });
    setLoading(true)
    try {
      // Send PUT request to update product
      const response = await sellerapiRequest(
        `/product/update/${productId}`,
        "PUT",
        formData
      );
      if (response.data) {
        showSuccessToast("Product Updated Successfully.");
      }
    } catch (error) {
      console.error("Error updating product", error);
      alert("Failed to update product");
    }
    setLoading(false)
  };

  return (
    <>
      <div className="home">
        <AdminNavbar />
        <div className="container home-padding">
            {loading &&<Loader />}
          <h3 className="mb-4">Edit Product</h3>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Product Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter product name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Category</label>
              <select
                className="form-select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required>
                <option value="">Select category</option>
                {categories.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Sale Price</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter sale price"
                value={salePrice}
                onChange={(e) => setSalePrice(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Quantity in Stock</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter quantity"
                value={quantityInStock}
                onChange={(e) => setQuantityInStock(e.target.value)}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Product Description</label>
              <textarea
                className="form-control"
                placeholder="Enter product description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="3"
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Existing Product Images</label>
              <div
                className="image-preview-container"
                style={{ display: "flex", gap: "15px" }}>
                {existingImages.length > 0 &&
                  existingImages.map((image, index) => (
                    <div key={index} className="image-preview-box">
                      <img
                        src={image} // Assuming 'image' is a URL string
                        alt={`Existing Preview ${index + 1}`}
                        className="image-preview"
                        width={"150px"}
                        height={"150px"}
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Upload New Images (Max 5)</label>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageChange}
                className="form-control"
              />
            </div>

            <div
              className="image-preview-container"
              style={{ display: "flex", gap: "15px" }}>
              {selectedImages.length > 0 &&
                selectedImages.map((image, index) => (
                  <div key={index} className="image-preview-box">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index + 1}`}
                      className="image-preview"
                      width={"150px"}
                      height={"150px"}
                    />
                  </div>
                ))}
            </div>
            <br />

            <div className="d-flex justify-content-between">
              <button type="button" className="btn btn-secondary">
                Save To Draft
              </button>
              <button type="submit" className="btn btn-primary">
                Update Product
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditProduct;
