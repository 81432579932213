import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ListProduct from "./Product/ListProduct";
import SoftProductList from "./Product/SoftProductList";

function SoftProduct() {
  return (
    <>
      <div className="home">
        <Navbar />
        <div className="soft">
          
        <div className="allproducts">
          <p>
            Home <span>{">"} Software</span>
          </p>
          <br />
          <h2>All </h2>
          <h2>Software</h2>
          <br />
          <h3>
          We’re gathering innovative product details from our creators and carefully  <br /> curating them for listing on our platform.
          </h3>
        </div>
        </div>

        <SoftProductList />

        <Footer />
      </div>
    </>
  );
}

export default SoftProduct;
