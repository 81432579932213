import React, { useState } from "react";
import img from "../Assets/Saly-14.png";
import icon from "../Assets/Facebook.png";
import icon2 from "../Assets/apple.png";
import icon3 from "../Assets/google.png";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../common/toast";
import apiRequest from "../common/apiRequest";
import Loader from "../loader/Loader";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import logo from '../Assets/bong.png'

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [selectedRole, setSelectedRole] = useState("customer"); // Default to customer
  const [loading, setLoading] = useState(false); // State to handle loader

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Regex for email validation (basic one)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation checks
    if (!formData.email) {
      showErrorToast("Email or Username is required");
      return;
    }

    if (!formData.password) {
      showErrorToast("Password is required");
      return;
    }

    // Optionally check if email is valid when used as login
    if (emailRegex.test(formData.email)) {
      console.log("Email format detected");
    }

    try {
      // Trigger loading
      setLoading(true);

      const dataToSend = {
        email: formData.email,
        password: formData.password
      };

      let response;
      // Send API request based on role (Customer or Innovator)
      if (selectedRole === "customer") {
        response = await apiRequest("/user/login", "POST", dataToSend);
      } else if (selectedRole === "innovator") {
        response = await apiRequest("/seller/login", "POST", dataToSend);
      }

      if (response) {
        showSuccessToast("Login Success");

        // Store user and token in localStorage based on role
        if (selectedRole === "customer") {
          localStorage.setItem(
            "bong_user",
            JSON.stringify(response?.data?.user)
          );
          localStorage.setItem("bong_user_token", response?.data?.token);
          navigate("/");
        } else if (selectedRole === "innovator") {
          localStorage.setItem(
            "bong_innovator",
            JSON.stringify(response?.data?.seller)
          );
          localStorage.setItem("bong_innovator_token", response?.data?.token);
          navigate("/adminhome"); // Navigate to Innovator home
        }
      }
    } catch (error) {
      console.error("Login Error:", error);
      showErrorToast("Login failed. Please try again.");
    }

    setLoading(false);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const googleLogin = async (data) => {
    // Ensure a role is selected before proceeding
    if (!selectedRole) {
      showSuccessToast.info("Please select a Profile.");
      return;
    }

    const decodedToken = jwtDecode(data?.credential);
    console.log(decodedToken);

    // Set loading to true while processing the login
    setLoading(true);

    // Check if the email is verified
    if (!decodedToken?.email_verified) {
      setLoading(false);
      showErrorToast("Account Validatiaon Failed.");
      return;
    }

    // Define the user payload for the API request
    const userPayload = {
      email: decodedToken?.email,
      name: decodedToken?.name,
      picture: decodedToken?.picture
    };

    let response;

    // Handle customer role
    if (selectedRole === "customer") {
      response = await apiRequest("/user/google/login", "POST", userPayload);
      if (response?.data) {
        localStorage.setItem("bong_user", JSON.stringify(response?.data?.user));
        localStorage.setItem("bong_user_token", response?.data?.token);
        navigate("/");
      }
    }

    // Handle innovator role
    if (selectedRole === "innovator") {
      response = await apiRequest("/seller/google/login", "POST", userPayload);
      if (response?.data) {
        localStorage.setItem(
          "bong_innovator",
          JSON.stringify(response?.data?.seller)
        );
        localStorage.setItem("bong_innovator_token", response?.data?.token);
        navigate("/adminhome");
      }
    }

    // Reset loading state after the login process
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="login-banner">
        <div className="login-banner-left">
        <Link to='/'>

        <img className="navlogo navlogo3" src={logo}/>
        </Link>
        <br/>
        <br/>
          <h2>Log in to</h2>
      

          <div className="login-banner-vec">
            <p>
              If you don’t have an account register
              <br /> You can{" "}
              <Link to="/signup">
                <span>Register here</span>
              </Link>{" "}
              !
            </p>

            <img src={img} alt="Signup banner" />
          </div>
        </div>
        <div className="login-banner-right">
          <div className="login-form">
            <h2>Log in</h2>
            <br />
            <div className="role-selector" style={{ display: "flex " }}>
              <label
                className={`radio-btn ${
                  selectedRole === "customer" ? "active" : ""
                }`}>
                <input
                  type="radio"
                  name="role"
                  value="customer"
                  checked={selectedRole === "customer"}
                  onChange={handleRoleChange}
                />
                Customer
              </label>

              <label
                className={`radio-btn ${
                  selectedRole === "innovator" ? "active" : ""
                }`}>
                <input
                  type="radio"
                  name="role"
                  value="innovator"
                  checked={selectedRole === "innovator"}
                  onChange={handleRoleChange}
                />
                Innovator
              </label>
            </div>
            <br />
            <form onSubmit={handleSubmit}>
              <input
                name="email"
                type="text"
                placeholder="Enter email or username"
                value={formData.email}
                onChange={handleInputChange}
              />
              <br />
              <br />

            
              <input
                name="password"
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <br />
              <br />

              <Link to="/forgot-password">
                <span>Forgot Password?</span>
              </Link>
              <br />
              <br />
              <button type="submit" disabled={loading}>
                {loading ? "Logging in..." : "Login"}
              </button>
              <br />
              <br />
            </form>

            <p>or continue with</p>
            <div className="continuewith">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  googleLogin(credentialResponse);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </div>
            <br />
            {/* <div className="continuewith">
              <img src={icon} alt="Facebook" />
              <img src={icon2} alt="Apple" />
              <img src={icon3} alt="Google" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
