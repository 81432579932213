import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import blog from "../Assets/image 160.png";

function Blog() {
  return (
   <>
    <div className='home'>
    <Navbar/> 
    <div className="blog-div">
          <h2 style={{textAlign:'center'}}>
            Check out our <span>blog</span>
          </h2>
          <br />
         
     
          <div className="blog-cards">
            <div className="blog-card">
              <img src={blog} />
              <div className="blog-card-text">
                <p>How to deal with Underperforming products?</p>
              
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>


    <Footer/>

    </div>
   </>
  )
}

export default Blog