import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import icon1 from "../Assets/Featured icon.png";
import icon2 from "../Assets/Chart mini.png";
import AdminNavbar from "../Components/AdminNavbar";
import sellerapiRequest from "../common/sellerapiRequest";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import AdminAllProduct from "./AdminAllProduct";

function AdminHome() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [sellerData, setSellerData] = useState(null)
  const [stageCounts, setStageCounts] = useState({
    productCounts: [],
    softProductCounts: [],
  });

  // API call to fetch product and soft product stage counts
  const fetchStageCounts = async () => {
    setLoading(true);
    try {
      const response = await sellerapiRequest("/product/combined-stage-count", "GET");
      if (response.data) {
        setStageCounts(response?.data);
      }
    } catch (error) {
      console.error("Error fetching stage counts", error);
    }
    setLoading(false);
  };
  console.log(stageCounts)

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('bong_innovator'))
    if(data){
      setSellerData(data)
    }
    fetchStageCounts(); 
  }, []);

  return (
    <>
      <div className="home">
        <AdminNavbar />
        <div className="dash">
          {
            loading && <Loader />
          }
          <div className="heading">
            <h1>{sellerData?.name||""}</h1>
            <button onClick={()=>navigate(`/addproduct`)}>Add New Product</button>
          </div>
          <br />

          {/* Dynamically show stage counts in cards */}
          <div className="dash-cards">
            {/* Product Counts */}
            {[ "approved", "pending", "process", "rejected"].map((stage) => (
              <div className="dash-card" key={stage}>
                <div className="dash-card-heading">
                  <img src={icon1} alt="icon" />
                  <p>{`Products ${stage.charAt(0).toUpperCase() + stage.slice(1)}`}</p>
                </div>
                <br />
                <div className="dash-card-text">
                  <div className="dash-card-text-left">
                    <h3>
                      {stageCounts?.productCounts?.find((item) => item?.stage === stage)?.count || 0}
                    </h3>
                    <p>{`Stage: ${stage.charAt(0).toUpperCase() + stage.slice(1)}`}</p>
                  </div>
                  <div className="dash-card-text-right">
                    <img src={icon2} alt="chart icon" />
                  </div>
                </div>
              </div>
            ))}

            {/* Soft Product Counts */}
            {[ "approved", "pending", "process", "rejected"].map((stage) => (
              <div className="dash-card" key={stage}>
                <div className="dash-card-heading">
                  <img src={icon1} alt="icon" />
                  <p>{`Soft Products ${stage.charAt(0).toUpperCase() + stage.slice(1)}`}</p>
                </div>
                <br />
                <div className="dash-card-text">
                  <div className="dash-card-text-left">
                    <h3>
                      { stageCounts?.softProductCounts?.find((item) => item?.stage === stage)?.count || 0}
                    </h3>
                    <p>{`Stage: ${stage.charAt(0).toUpperCase() + stage.slice(1)}`}</p>
                  </div>
                  <div className="dash-card-text-right">
                    <img src={icon2} alt="chart icon" />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* admin product */}
          {/* <AdminAllProduct /> */}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AdminHome;
