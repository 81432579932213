import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import apiRequest from '../../common/apiRequest'; 
import { showErrorToast } from '../../common/toast'; 
import Loader from '../../loader/Loader'; 
import AdminNavbar from "../../Components/AdminNavbar";

function AdminSoftProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null); 
  const [loading, setLoading] = useState(true); 

  // Fetch product details from the API
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await apiRequest(`/softproduct/single/${id}`, 'GET'); 
        setProduct(response?.data); 
      } catch (error) {
        console.error('Error fetching product:', error);
        showErrorToast('Failed to load product details'); 
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  return (
    <>
      <div className="home">
      <AdminNavbar />
        <div className="path">
          <p>
            Home {">"} Soft Products {">"} <span>{product?.productName || 'Product'}</span>
          </p>
        </div>
        {loading ? (
          <Loader /> 
        ) : (
          <div className="ProductDetail">
            <div className="ProductDetail-left">
              <div className="main-img">
                <img src={product?.productImage} alt="Main product" /> {/* Display product image */}
              </div>
            </div>
            <div className="ProductDetail-right">
              <h2>{product?.productName}</h2>
              <h3>INR{product?.price}</h3>
              <p>Stage: {product?.stage}</p>
              <p>Product Type: {product?.productType}</p>
              <p>Seller: {product?.sellerId?.name} ({product?.sellerId?.email})</p>
              <p>Software Version: {product?.softwareVersion}</p>
              <p>GitHub Repository: <a href={product?.gitRepoLink} target="_blank" rel="noopener noreferrer">{product?.gitRepoLink}</a></p>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AdminSoftProductDetail;
