import React from "react";
import Navbar from "../Components/Navbar";
import img from "../Assets/Group 1000008911.png";
import icon1 from "../Assets/slid1 (1).png";
import icon2 from "../Assets/slid1 (2).png";
import icon3 from "../Assets/slid1 (3).png";
import icon4 from "../Assets/slid1 (4).png";
import icon5 from "../Assets/slid1 (5).png";
import icon6 from "../Assets/slid1 (6).png";
import img2 from "../Assets/count.jpeg";
import img3 from "../Assets/bong png (3).png";
import inova from '../Assets/bong png (1).png'
import cng from '../Assets/bong png (2).png'

import speaker from "../Assets/speaker.png";
import shipicon from "../Assets/SRX-1.svg.png";
import ship from "../Assets/Group 273053.png";
import cagro from "../Assets/cargoX.svg.png";
import launch from "../Assets/launchX.svg.png";
import img4 from "../Assets/i.jpeg";
import img5 from "../Assets/image 150.png";
import Fulfillment from "../Assets/fulfillment-64ad1a6a8dabf.webp.png";
import Conversion from "../Assets/4.png";
import Conversion2 from "../Assets/2.png";
import Conversion3 from "../Assets/3.png";
import Conversion4 from "../Assets/1.png";

import foranywhere from "../Assets/Group 1000008920.png";
import worktogether from "../Assets/image 157.png";
import oneplace from "../Assets/Item → cllogo-1.png.png";
import oneplace2 from "../Assets/Item → cllogo-2.png.png";
import oneplace3 from "../Assets/Item → cllogo-4.png.png";
import oneplace4 from "../Assets/Item → partner-2.webp.png";
import oneplace5 from "../Assets/Item → partner-3.webp.png";
import oneplace6 from "../Assets/Item → partner-4.webp.png";

import quite from "../Assets/quoteLeft.svg.png";
import blog from "../Assets/image 160.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

import AdminNavbar from "../Components/AdminNavbar";

function MainHome() {
  return (
    <>
      <div className="home">
        <AdminNavbar />
        <div className="banner">
          <div className="banner-left">
            <h1>
              <span className="gradient-text">
              Welcome @<br/>
              World of Innovations
              </span>
              👋
            </h1>
            <p>
            Innovations are listed for the world<br/>
            Come and expirience the future, Today!
            </p>
            <br />
            <br />
<div className="banner-btn"> 
  <Link to='/allproduct'>

<button>Shop Now</button>
  </Link>
  <Link to='/login'>

            <button>Start Shopping</button>
  </Link>

</div>

            <br />
            <br />
   
          </div>
          <div className="banner-right">
            <img src={img} />
          </div>
        </div>
        <br/>
      
        <div className="marquee-container">
          <div className="marquee">
          {/* <div className="getgift">
          <h1>Register today and get a chance to win a T-Shirt from US</h1>
      

        </div>
        <div className="getgift">
          <h1>0% commission free to join free to access</h1>
      

        </div> */}
       
        <div className="getgift">

        <h1>Register today and get a chance to win a T-Shirt from US.</h1>
        <h1> 0% commission, free to join free to access.</h1>
        <h1>Register today and get a chance to win a T-Shirt from US.</h1>
        <h1> 0% commission, free to join free to access.</h1>
        <h1>Register today and get a chance to win a T-Shirt from US.</h1>
        <h1> 0% commission, free to join free to access.</h1>
        <h1>Register today and get a chance to win a T-Shirt from US.</h1>
        <h1> 0% commission, free to join free to access.</h1>
        <h1>Register today and get a chance to win a T-Shirt from US.</h1>
        <h1> 0% commission, free to join free to access.</h1>

          
          </div>
          </div>
        </div>
      
        <div className="growthpartner">
        <h3>A platform for limitless posibilities</h3>
          <h2>
          Lakhs of students create projects every year to <span className="growthpartner-span">
            get their degree. But what about their dream?

            </span>
        
          </h2>
        </div>
        <div className="growthpartner-div">
          <div className="growthpartner-div-left">

<h3 className="CurrentMarket">Current Market Research</h3>
       <div className="growthpartner-lines">



       <div className="count-div">
          <div className="growthpartner-div-section">
              <h3>15 Crore+</h3>
              <p>Students Every Year</p>
              <div className="line"></div>
            </div>
            <div className="growthpartner-div-section">
              <h3>20 Crore+</h3>
              <p>Transactions Every Year</p>
              <div className="line"></div>
            </div>
          </div>

          <div className="count-div">

            <div className="growthpartner-div-section">
              <h3>10 Crore+</h3>
              <p>Projects Count Every Year</p>
              <div className="line"></div>
            </div>
            <div className="growthpartner-div-section">
              <h3>100 Crore+</h3>
              <p>Possible Market Cap</p>
              <div className="line"></div>
            </div>
          </div>
       </div>

          </div>
          <div className="growthpartner-div-right ">
            <img src={img2} />
          </div>
        </div>
        <div className="growthpartner">
          <h2 style={{textAlign:'center'}}>
          Enhance your journey to excellence
           
            <span className="growthpartner-span"> at every scroll of this website</span>
          </h2>
        </div>
        <div className="shipping-heading">
          <h3>
            <span className="shipping-text">Innovation</span> For a better world
          </h3>
        </div>
        <div className="shipping-cards">
          <div>
            <div className="shipping-card">
              <img src={img3} />
              <br />
              <br />
              <h3>Create Impact</h3>
              {/* <p>
                Manage all channels in a single view and reach nationwide
                efficiently with AI- based courier selection
              </p>
              <br />
              <h4>Explore</h4> */}
            </div>
          </div>
          <div>
            <div className="shipping-card">
              <img src={inova} />
              <br />
              <br />
              <h3>Expirience Innovation</h3>
              {/* <p>
                Manage all channels in a single view and reach nationwide
                efficiently with AI- based courier selection
              </p>
              <br />
              <h4>Explore</h4> */}
            </div>
          </div>
          <div>
            <div className="shipping-card">
              <img src={cng} />
              <br />
              <br />
              <h3>Lead the Change</h3>
              {/* <p>
                Manage all channels in a single view and reach nationwide
                efficiently with AI- based courier selection
              </p>
              <br />
              <h4>Explore</h4> */}
            </div>
          </div>
        </div>
        <div className="quickdemo-div">
          <div className="quickdemo">
            <img src={speaker} />
            <p>
              Experience Bong in real-time with our live demo.{" "}
              <span>Sign-up not required for demo</span>
            </p>
            <Link to='/login'>

            <button>Sign Up</button>
            </Link>
          </div>
        </div>
        <div className="export-heading">
          <h3>
            <span className="export-text">Project: Kimberlight</span>
            <br />
            Where Dreams of Innovation Meet the World - Buy the Future, Today!
          </h3>
        </div>
        <div className="export">
          <div className="export-left">
            {/* <img src={shipicon} /> */}
          
            {/* <img src={ship} /> */}
            <h3>Join us as Innovator</h3>
          

            <p>
            Transform your project into possibilities—become an innovator with us!
            </p>
         

            <div className="line2"></div>

            <br />
            <br />
            <h3>Experience innovation firsthand as a customer</h3>


            <p>
            Discover unique projects crafted by bright minds and unlock solutions that inspire!
            </p>
           
            <div className="line2"></div>

            <br />
            <br />
            <h3>Lead the change as an investor</h3>
            

            <p>
            Empower the next generation of innovators and fuel groundbreaking ideas with your support!
            </p>
          
            <div className="line2"></div>
          </div>
          <div className="export-right">
            <img src={img4} />
          </div>
        </div>
        {/* <div className="export-heading">
          <h3>
            <span className="export-text">Delight</span>
            <br />
            Elevate customer experience
          </h3>
        </div>
        <div className="Delight-cards">
          <div className="Delight-card-div">
            <div className="Delight-card">
              <img src={img5} />
              <br />
              <br />

              <h3>Tracking page</h3>
              <br />

              <p>
                Build trust with your brand name, logo, offers and support
                details on your order-tracking page
              </p>
              <br />

              <h4>Explore </h4>
            </div>
          </div>

          <div className="Delight-card-div">
            <div className="Delight-card">
              <img src={img5} />
              <br />
              <br />

              <h3>Tracking page</h3>
              <br />

              <p>
                Build trust with your brand name, logo, offers and support
                details on your order-tracking page
              </p>
              <br />

              <h4>Explore </h4>
            </div>
          </div>
          <div className="Delight-card-div">
            <div className="Delight-card">
              <img src={img5} />
              <br />
              <br />

              <h3>Tracking page</h3>
              <br />

              <p>
                Build trust with your brand name, logo, offers and support
                details on your order-tracking page
              </p>
              <br />

              <h4>Explore </h4>
            </div>
          </div>
        </div> */}
        {/* <div className="export-heading">
          <h3>
            <span className="export-text">Fulfillment</span>
            <br />
            Fulfill every need
          </h3>
        </div>
        <div className="Fulfillment">
          <div className="Fulfillment-left">
            <h3>B2C fulfillment</h3>
            <br />
            <p>
              Store inventory closest to your customers & deliver their packages
              on the same or the next day
            </p>
         
            <div className="line2"></div>
            <br />
            <br />

            <h3>B2C fulfillment</h3>
            <br />
            <p>
              Store inventory closest to your customers & deliver their packages
              on the same or the next day
            </p>
        
          </div>
          <div className="Fulfillment-right">
            <img src={Fulfillment} />
          </div>
        </div> */}
        <div className="export-heading">
          <h3>
            <span className="export-text">Why BuyMyProject?</span><br/>
             Because once BuyMyProject was only a dream in a paper. We made it happen.
          </h3>
        </div>
        <div className="Conversion-cards">
        
          <div className="Conversion-card">
            <img src={Conversion} />
            <br />
            <br />
            <h3>Trust the mission</h3>
            {/* <br />
            <p>
              Show Shiprocket’s badge of trust throughout your website to boost
              credibility
            </p> */}
         
          </div>
          <div className="Conversion-card">
            <img src={Conversion2} />
            <br />
            <br />
            <h3>People are at core</h3>
            {/* <br />
            <p>
            Automate your marketing campaigns and increase conversions
            by 40%
            </p> */}
        
          </div>
          <div className="Conversion-card">
            <img src={Conversion3} />
            <br />
            <br />
            <h3>We Care</h3>
            {/* <br />
            <p>
            Speed up checkout by 70% with pre-filled details and
            intelligent fraud detection
            </p> */}
        
          </div>
          <div className="Conversion-card">
            <img src={Conversion4} />
            <br />
            <br />
            <h3>We Promote – You Celebrate</h3>
            {/* <br />
            <p>
            Re-engage with high-potential prospects and increase
customer retention by 25%
            </p> */}
       
          </div>
        </div>
        <br />
        <br />
        <div className="foranywhere">
          <div className="foranywhere-left">
            <h3>
              From anywhere
              <br />
              <span> to everywhere</span>
            </h3>
            <br />
            <p>
              Our multi-courier network spread across 24000+ pin codes lets you
              say yes to every order, even from remote areas.
            </p>
            
            
          </div>
          <div className="foranywhere-right">
            <img src={foranywhere} />
          </div>
        </div>

       
        {/* <div className="oneplace">
          <h2>
            Manage multiple channels in <span>one place</span>
          </h2>
          <br />
          <p>
            Integrate 12+ sales channels to easily manage your orders, inventory
            and more
          </p>
          <br />
          <h3>Explore integrations </h3>
          <br />
          <br />
          <br />
          <div className="oneplace-card">
            <img src={oneplace} />
            <img src={oneplace2} />
            <img src={oneplace3} />
            <img src={oneplace4} />
            <img src={oneplace5} />
            <img src={oneplace6} />
          </div>
        </div> */}
        <div className="whatwedo">
          <h2>
            <span>Why we do</span> what we do
          </h2>
          <h4>What People think about this journey?</h4>
          <div className="whatwedo-center">
            <img src={quite} />
            <p>
            Being a student I always tried to do something that has an impact. Creating a project just to get a degree doesn't make sense for me. It should have a purpose. A purpose that can lead to a positive change. It should inspire other students to do something great, think out of the box and finally lead to innovation. Sharing knowledge is the core of our business and sharing royalty is the model of the business. Happy learning and share if you care.
         
          
            </p>
          </div>
          <div className="des">

           <h3> ~ Subhendu Bhattacharyya <br/><span>(Microsoft Certified Trainer)</span></h3>
          </div>
        </div>
        <div className="worktogether">
          <h2>
          Let’s work together –  <span>Let’s grow together</span>
          </h2>
          {/* <h3>Seamlessly connected components</h3> */}

          <p>
          We are open to collaborate with any individual or company to make
bigger impact.
          </p>
          {/* <br />
          <button>Send Email</button> */}

        
{/* 
          <div className="worktogether-cards">
            <div className="worktogether-card">
              <img src={worktogether} />
              <br />
              <br />
              <h2>Over 1 Trillion data points</h2>
              <br />

              <p>
                Be it reducing RTO or improving conversions, utilise our
                AI-driven analytics to achieve it all smartly
              </p>
              <br />
              <h3>Explore </h3>
            </div>
            <div className="worktogether-card">
              <img src={worktogether} />
              <br />
              <br />
              <h2>Over 1 Trillion data points</h2>
              <br />

              <p>
                Be it reducing RTO or improving conversions, utilise our
                AI-driven analytics to achieve it all smartly
              </p>
              <br />
              <h3>Explore </h3>
            </div>
            <div className="worktogether-card">
              <img src={worktogether} />
              <br />
              <br />
              <h2>Over 1 Trillion data points</h2>
              <br />

              <p>
                Be it reducing RTO or improving conversions, utilise our
                AI-driven analytics to achieve it all smartly
              </p>
              <br />
              <h3>Explore </h3>
            </div>
          </div> */}
        </div>

        {/* <div className="headlines">
          <h2>
            How we’re hitting <span>headlines</span>
          </h2>
        </div>

        <div className="marquee-container">
          <div className="marquee">
            <img src={icon1} alt="icon1" />
            <img src={icon2} alt="icon2" />
            <img src={icon3} alt="icon3" />
            <img src={icon4} alt="icon4" />
            <img src={icon5} alt="icon5" />
            <img src={icon6} alt="icon6" />
            <img src={icon1} alt="icon1" />
            <img src={icon2} alt="icon2" />
            <img src={icon3} alt="icon3" />
            <img src={icon4} alt="icon4" />
            <img src={icon5} alt="icon5" />
            <img src={icon6} alt="icon6" />
            <img src={icon1} alt="icon1" />
            <img src={icon2} alt="icon2" />
            <img src={icon3} alt="icon3" />
            <img src={icon4} alt="icon4" />
            <img src={icon5} alt="icon5" />
            <img src={icon6} alt="icon6" />
            <img src={icon1} alt="icon1" />
            <img src={icon2} alt="icon2" />
            <img src={icon3} alt="icon3" />
            <img src={icon4} alt="icon4" />
            <img src={icon5} alt="icon5" />
            <img src={icon6} alt="icon6" />
            <img src={icon1} alt="icon1" />
            <img src={icon2} alt="icon2" />
            <img src={icon3} alt="icon3" />
            <img src={icon4} alt="icon4" />
            <img src={icon5} alt="icon5" />
            <img src={icon6} alt="icon6" />
          </div>
        </div> */}

        {/* <div className="blog-div">
          <h2>
            Check out our <span>blog</span>
          </h2>
          <br />
          <br />
          <br />
          <div className="blog-cards">
            <div className="blog-card">
              <img src={blog} />
              <div className="blog-card-text">
                <p>Boost eCommerce Success with User Tracking and…</p>
                <br />
                <br />
                <h3>Know more </h3>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div> */}
   
      </div>
      <Footer />
    </>
  );
}

export default MainHome;
