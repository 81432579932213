import React, { useEffect, useState } from "react";
import sellerapiRequest from "../common/sellerapiRequest";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";

function SoftProductTable() {
  const navigate = useNavigate();
  const [softProducts, setSoftProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSoftProducts = async () => {
      setLoading(true);
      try {
        const response = await sellerapiRequest("/softproduct/self", "GET");
        if (response.data) {
          setSoftProducts(response.data);
        }
      } catch (error) {
        console.error("Error fetching soft products:", error);
      }
      setLoading(false);
    };

    fetchSoftProducts();
  }, []);

  return (
    <div className="newover">
      {loading && <Loader />}
      <h3>Soft Product List</h3>
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>Image</th>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Price</th>
            <th>Seller</th>
            <th>Stage</th>
            <th>Git Repo</th>
            <th>Version</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {softProducts.map((softProduct, index) => (
            <tr key={index}>
              <td>
                {/* Display product image */}
                {softProduct.productImage ? (
                  <img
                    src={softProduct.productImage}
                    alt={softProduct.productName}
                    width="50"
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td>{softProduct.productName}</td>
              <td>{softProduct.productType}</td>
              <td>${softProduct.price}</td>
              <td>
                {softProduct.sellerId
                  ? softProduct.sellerId.name
                  : "Unknown Seller"}
              </td>
              <td>{softProduct.stage}</td>
              <td>
                {/* Display Git Repo link if it exists */}
                {softProduct.gitRepoLink ? (
                  <a
                    href={softProduct.gitRepoLink}
                    target="_blank"
                    rel="noopener noreferrer">
                    GitHub Link
                  </a>
                ) : (
                  <span>No Repo</span>
                )}
              </td>
              <td>{softProduct.softwareVersion}</td>
              <td>
                <button className="btn btn-primary" onClick={()=>navigate(`/edit-project/${softProduct?._id}`)}>Edit</button>
                {/* <button className="btn btn-danger">Delete</button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SoftProductTable;
