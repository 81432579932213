import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import back from '../Assets/back-svgrepo-com 1.png'
import icon1 from '../Assets/Featured icon.png'
import icon2 from '../Assets/Chart mini.png'
import AdminNavbar from '../Components/AdminNavbar'

function Transactions() {
    const data = [
        {
            
            Date: '01/03/2023',
            Seller: 'Academy',
            SKU: '123456',
            Type: 'Withdrawal',
            Curr: 'FJD',
            Fee: '2565.24',
            Tax: '0.6',
            Total: '$2548.85',


          
          id: 1,
     
          Actions: '',
    
    
    
    
    
        },
    
      ];
  return (
    <>
    <div className='home'>
   <AdminNavbar/>

    <div className='home-padding'>

    <div className='add-heading'>
<img src={back}/>
<h3>Transactions</h3>

</div>
<br/>

<div className='allproduct-color'>
<h3>Transaction</h3>
<p>Transaction Date</p>
<br/>
<div className='allproduct-inps'>
<div>

<select id="fruits" name="fruits">
    <option value="apple">Apple</option>
    <option value="banana">Banana</option>
    <option value="orange">Orange</option>
    <option value="mango">Mango</option>
</select>
</div>






</div>
<br/>
<br/>





<div className="newover">
 
  <table className="table table-striped table-bordered">
    <thead className="thead-dark">
      <tr className='table-headings'>
       
        <th>Date & Time</th>
        <th>Seller</th>
        <th>SKU</th>
        <th>Type</th>
        <th>Status</th>
        <th>Curr</th>
        <th>Fee</th>
        <th>Tax</th>
        <th>Total</th>


      


        
       
     


      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={item.id}>
       

          {/* <td>
            <img
              src={item.img}
              alt={item.ProductName}
              className="img-fluid rounded"
              style={{ width: '50px', height: '50px' }}
            />
          </td> */}
          <td>{item.Date}</td>
          <td>{item.Seller}</td>
          <td>{item.SKU}</td>
         
          <td>{item.Type}</td>
          <td><button className='orderstatus-btn'>Complete</button></td>
          <td>{item.Curr}</td>
          <td>{item.Fee}</td>
          <td>{item.Tax}</td>
          <td>{item.Total}</td>




          
          <td></td>



        


        </tr>
      ))}
    </tbody>
  </table>
</div>
</div>





</div>


   


    <Footer/>

    </div>
</>
  )
}

export default Transactions