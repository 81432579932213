import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import back from '../Assets/back-svgrepo-com 1.png'
import icon1 from '../Assets/Featured icon.png'
import icon2 from '../Assets/Chart mini.png'
import AdminNavbar from '../Components/AdminNavbar'

function Invoices() {
    const data = [
        {
            InvoiceNo: '#12345',
            SentDate: '01/03/2023',
            CustomerName: 'Jones Bonn',
            Rceivedate: '01/03/2023',
          Payment: '$1200',

          id: 1,
        //   img: 'https://via.placeholder.com/50',
        //   ProductName: 'Xiaomi Wifi Repeater Pro',
        //   SKU: 'TR-001',
        //   Category:'Electronics',
        //   Payment: '$600 Fully Paid',
          // Price: '$254.00',
          
          // Type: 'Simple',
          // Statistics: 'Best Seller',
          // tag: 'Top Rated Best Seller, phone',
    
    
          // Rate: '*',
          // Date: 'Last Modifies:23/03/2024',
          Actions: '',
    
    
    
    
    
        },
    
      ];
  return (
    <>
    <div className='home'>
   <AdminNavbar/>

    <div className='home-padding'>

    <div className='add-heading'>
<img src={back}/>
<h3>Invoices</h3>

</div>
<br/>

<div className='allproduct-color'>
<h3>Invoice List</h3>
<p>Invoice Date</p>
<br/>
<div className='allproduct-inps'>
<div>

<select id="fruits" name="fruits">
    <option value="apple">Apple</option>
    <option value="banana">Banana</option>
    <option value="orange">Orange</option>
    <option value="mango">Mango</option>
</select>
</div>

<div>

<select id="fruits" name="fruits">
    <option value="apple">Apple</option>
    <option value="banana">Banana</option>
    <option value="orange">Orange</option>
    <option value="mango">Mango</option>
</select>
</div>





</div>
<br/>
<br/>





<div className="newover">
 
  <table className="table table-striped table-bordered">
    <thead className="thead-dark">
      <tr className='table-headings'>
       
        <th>Invoice No</th>
        <th>Sent Date</th>
        <th>Customer Name</th>
        <th>Rceive date</th>
        <th>Payment</th>
        <th>Status</th>
        <th>Actions</th>

      


        
       
     


      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={item.id}>
       

          {/* <td>
            <img
              src={item.img}
              alt={item.ProductName}
              className="img-fluid rounded"
              style={{ width: '50px', height: '50px' }}
            />
          </td> */}
          <td>{item.InvoiceNo}</td>
          <td>{item.SentDate}</td>
          <td>{item.CustomerName}</td>
         
          <td>{item.Rceivedate}</td>
          <td>{item.Payment}</td>
          <td><button className='orderstatus-btn'>Complete</button></td>
          <td></td>



          
          <td></td>



        


        </tr>
      ))}
    </tbody>
  </table>
</div>
</div>





</div>


   


    <Footer/>

    </div>
</>
  )
}

export default Invoices