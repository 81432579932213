import axios from 'axios';
import { showSuccessToast, showErrorToast } from './toast';
import { baseUrl } from './data'; // Assuming `baseUrl` is defined here

const sellerapiRequest = async (url, method = 'GET', data = null) => {
  try {
    // Prepend baseUrl to the provided endpoint
    const fullUrl = `${baseUrl}${url}`;
    const token = localStorage.getItem('bong_innovator_token');
    const response = await axios({
      url: fullUrl, // Full URL (baseUrl + endpoint)
      method,
      data,
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    showErrorToast(error?.response?.data?.message);
  } finally {
  }
};

export default sellerapiRequest;
