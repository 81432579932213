import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Privacy() {
  return (

    <>
      <div className="home">
        <Navbar />
        <div className='privacy'>
        <h1>Privacy Policy</h1>
        <br/>
      
    <p>Bong Innovations (OPC) Private Limited ("We", "Us", "Our"), operating under the brand name BuyMyProject, is committed to protecting the privacy of its users. This Privacy Policy outlines the types of information we collect from users, how we use and disclose that information, and the steps we take to safeguard your personal data when you use our website (<a href="http://buymyproject.in">http://buymyproject.in</a>).</p>

    <h2>Information We Collect</h2>
    <p>We collect a range of personal information, including but not limited to:</p>
    <ul>
        <li><strong>Personal Identification Information:</strong> Name, email address, contact number, billing information.</li>
        <li><strong>Project Information:</strong> Details related to the projects you submit or purchase.</li>
        <li><strong>Payment Data:</strong> Information related to payment methods, billing addresses, and transaction details.</li>
        <li><strong>Usage Data:</strong> Data such as IP address, device type, operating system, browser type, and browsing behavior, collected via cookies and other tracking technologies.</li>
    </ul>

    <h2>Purpose of Collection</h2>
    <p>We collect your personal information to provide you with our services, process transactions, improve our platform, and communicate with you. This includes:</p>
    <ul>
        <li>Verifying your identity and processing payments.</li>
        <li>Offering tailored content and recommendations based on your preferences.</li>
        <li>Conducting surveys and gathering feedback for improving our services.</li>
        <li>Protecting against fraud and unauthorized transactions.</li>
        <li>Complying with legal obligations.</li>
    </ul>

    <h2>Data Sharing and Disclosure</h2>
    <p>We may share your personal information with:</p>
    <ul>
        <li><strong>Service Providers:</strong> Third parties who assist us in providing services (e.g., payment gateways, hosting services).</li>
        <li><strong>Legal Authorities:</strong> When required by law, or in response to legal processes or to protect our rights.</li>
        <li><strong>Marketing Partners:</strong> For tailored advertisements, with your consent (default by yes).</li>
    </ul>
    <p>Your personal data may be shared with our partners in accordance with applicable laws, and we take appropriate steps to ensure its security during transmission and storage.</p>

    <h2>Data Security</h2>
    <p>We implement reasonable physical, electronic, and administrative safeguards to protect the confidentiality and security of your data. However, data transmitted over the internet is not completely secure, and we cannot guarantee the complete protection of your personal information.</p>

    <h2>Cookies</h2>
    <p>We use data collection devices such as "cookies" on certain pages of the Platform to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal data. We offer certain features that are only available through the use of a "cookie".</p>
    <p>We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case, you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session.</p>
    <p>Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties.</p>
    <p>We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics helps us understand how our customers use the site. You can read more about how Google uses your personal data <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">here</a>. You can opt-out of Google Analytics <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>. You can also control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on the services.</p>

    <h2>Cookies and Tracking Technologies</h2>
    <p>Our platform uses cookies and similar tracking technologies to provide functionality and improve user experience. Cookies help us store user preferences and deliver relevant advertisements. You can manage your cookie preferences through your browser settings.</p>

    <h2>Third-Party Links</h2>
    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of those websites and recommend reviewing their respective privacy policies.</p>

    <h2>User Rights</h2>
    <p>You have the right to:</p>
    <ul>
        <li>Access, Update, or Delete your personal information stored with us.</li>
        <li>Opt-out of receiving non-essential marketing communications by clicking the “Unsubscribe” link in promotional emails.</li>
        <li>Request Data Portability in certain cases.</li>
    </ul>
    <p>You can exercise these rights by contacting us at <a href="mailto:ceo@bonginnovations.io">ceo@bonginnovations.io</a>.</p>

    <h2>Children’s Privacy</h2>
    <p>Our services are not intended for use by individuals under the age of 18. We do not knowingly collect personal data from children. If we become aware of such data being collected, we will delete it.</p>

    <h2>Retention of Data</h2>
    <p>We retain personal data for as long as necessary to provide our services or comply with legal obligations. Data may be retained in anonymized form for research and analytical purposes.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may modify this policy from time to time. You are encouraged to review this policy periodically for updates. Your continued use of our services constitutes acceptance of the updated policy.</p>

    <h2>Contact Information</h2>
    <p>If you have any questions or concerns regarding this Privacy Policy, you can reach us at:</p>
    <ul>
        <li><strong>Company Name:</strong> Bong Innovations (OPC) Private Limited</li>
        <li><strong>Brand:</strong> BuyMyProject</li>
        <li><strong>Website:</strong> <a href="http://buymyproject.in">http://buymyproject.in</a>, <a href="https://BongInnovations.io">https://BongInnovations.io</a></li>
        <li><strong>Email:</strong> <a href="mailto:ceo@bonginnovations.io">ceo@bonginnovations.io</a></li>
    </ul>

        </div>
        <Footer/>

        </div>
    </>
  )
}

export default Privacy