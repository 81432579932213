import React, { useState } from 'react';
import AdminNavbar from "../Components/AdminNavbar";
import AddProduct from './AddProduct'; 
import AddProject from './AddProject'; 

function ParentComponent() {
  const [selectedComponent, setSelectedComponent] = useState('product'); // By default, 'product' section selected

  const handleComponentChange = (type) => {
    setSelectedComponent(type); // 'product' or 'project' ke hisaab se change hoga
  };

  return (
    <div className="home">
      <AdminNavbar />
      <div className=" home-padding">
    <div className='home-board-padding'>
    <div className="add-heading">
          <h3>Manage Products and Projects</h3>
        </div>
        <br />
        <div className="button-group mb-4">
  <button
    className={`btn ${selectedComponent === 'product' ? 'btn-primary' : 'btn-secondary'}`}
    style={{
      backgroundColor: selectedComponent === 'product' ? 'rgba(115, 90, 229, 1)' : 'white',
      color: selectedComponent === 'product' ? 'white' : 'rgba(115, 90, 229, 1)',
      borderColor: 'rgba(115, 90, 229, 1)'
    }}
    onClick={() => handleComponentChange('product')}
  >
    Add Product
  </button>&nbsp;&nbsp;
  <button
    className={`btn ${selectedComponent === 'project' ? 'btn-primary' : 'btn-secondary'}`}
    style={{
      backgroundColor: selectedComponent === 'project' ? 'rgba(115, 90, 229, 1)' : 'white',
      color: selectedComponent === 'project' ? 'white' : 'rgba(115, 90, 229, 1)',
      borderColor: 'rgba(115, 90, 229, 1)'
    }}
    onClick={() => handleComponentChange('project')}
  >
    Add Project
  </button>
</div>


    </div>

        {/* Conditionally rendering components */}
        {selectedComponent === 'product' ? <AddProduct /> : <AddProject />}
      </div>
    </div>
  );
}

export default ParentComponent;
