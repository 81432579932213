import React from 'react'
import logo from '../Assets/bong.png'
import social from '../Assets/socal (1).png'
import social2 from '../Assets/socal (2).png'
import social3 from '../Assets/socal (3).png'
import social4 from '../Assets/socal (4).png'
import social5 from '../Assets/socal (5).png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <div className='footer'>
    <div className='top-footer'>

    <div className='social-div'>
        <img className='footlogo' src={logo}/>
        <div className='social-icon'>
        <img src={social}/>
       
        <img src={social3}/>
        <img src={social4}/>
        <img src={social5}/>



        </div>
    </div>
    <br/>
    <br/>
    <br/>
    <div className='line3'></div>
    <br/>
    <br/>
    <div className='footer-links'>
      
  

        <ul>
        <Link className='none' to='/'>

<li>Home</li>
</Link>
        <Link className='none' to='/allproduct'>

            <li>Products</li>
        </Link>
        <Link className='none' to='/softproduct'>

<li>Software</li>
</Link>
  <Link className='none' to='/wishlist'>

<li>Wishlist</li>
</Link>
 
            <Link className='none' to='/terms'>
            <li>Terms of use</li>
</Link>
            <Link className='none' to='/privacy'>

            <li>Privacy policy</li>
            </Link>
            <li><button>Contact Us</button></li>


        
        </ul>
    </div>
  


    </div>
    <br/>
<div className='footer-bottom'>
<p style={{color:'white' ,textAlign:'center'}}>© Bong Innovations (OPC) Pvt Ltd All rights Reserved. Designed & Developed by <Link style={{color:'white' , textDecoration:'none'}} to='https://www.strixdigital.in/'>Strix Digital</Link></p>

</div>

    </div>

    </>
  )
}

export default Footer