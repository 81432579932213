import React, { useState } from "react";
import { showErrorToast, showSuccessToast } from "../common/toast";
import apiRequest from "../common/apiRequest";
import Loader from "../loader/Loader";
import img from "../Assets/Saly-14.png";
import { Link } from "react-router-dom";
import logo from '../Assets/bong.png'

function ForgotPassword() {
  const [step, setStep] = useState(1); // Step 1: Send OTP, Step 2: Verify OTP and Set Password
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    password: ""
  });
  const [loading, setLoading] = useState(false); // Loader state
  const [selectedRole, setSelectedRole] = useState("customer"); // Default role

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Send OTP handler
  const handleSendOTP = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      showErrorToast("Email is required");
      return;
    }

    try {
      setLoading(true);
      const endpoint =
        selectedRole === "customer"
          ? "/user/forgot/sendotp"
          : "/seller/forgot/sendotp";

      const response = await apiRequest(endpoint, "POST", {
        email: formData.email
      });
      if (response) {
        showSuccessToast("OTP sent successfully");
        setStep(2); // Move to next step (OTP verification and password set)
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      showErrorToast("Failed to send OTP");
    }
    setLoading(false);
  };

  // Verify OTP and Reset Password handler
  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    if (!formData.otp || !formData.password) {
      showErrorToast("OTP and New Password are required");
      return;
    }

    try {
      setLoading(true);
      const endpoint =
        selectedRole === "customer"
          ? "/user/forgot/verifyotp"
          : "/seller/forgot/verifyotp";

      const response = await apiRequest(endpoint, "POST", {
        email: formData.email,
        otp: formData.otp,
        password: formData.password
      });

      if (response) {
        setFormData({
          email: "",
          otp: "",
          password: ""
        });
        setStep(1);
        showSuccessToast("Password reset successfully");
        // Optionally redirect user to login page
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      showErrorToast("Failed to verify OTP");
    }
    setLoading(false);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  return (
    <>
      {loading && <Loader />} {/* Show loader if loading */}
      <div className="login-banner">
        <div className="login-banner-left">
        <Link to='/'>

        <img className="navlogo" src={logo}/>
        </Link>
        <br/>
        <br/>
          <h2>Forgot Password</h2>
     

          <div className="login-banner-vec">
            <p>
              If you have an account
              <br /> You can{" "}
              <Link to="/login">
                <span>Login here</span>
              </Link>{" "}
              !
            </p>

            <img src={img} alt="Signup banner" />
          </div>
        </div>
        <div className="login-banner-right">
          <div className="login-form">
            <h2>Forgot Password</h2>
            <br />
            {/* Role selector */}
            <div className="role-selector" style={{ display: "flex " }}>
              <label
                className={`radio-btn ${
                  selectedRole === "customer" ? "active" : ""
                }`}>
                <input
                  type="radio"
                  name="role"
                  value="customer"
                  checked={selectedRole === "customer"}
                  onChange={handleRoleChange}
                  disabled={step == 2}
                />
                Customer
              </label>
              <label
                className={`radio-btn ${
                  selectedRole === "innovator" ? "active" : ""
                }`}>
                <input
                  type="radio"
                  name="role"
                  value="innovator"
                  checked={selectedRole === "innovator"}
                  onChange={handleRoleChange}
                  disabled={step == 2}
                />
                Innovator
              </label>
            </div>
            <br />
            {step === 1 ? (
              // Step 1: Send OTP
              <form onSubmit={handleSendOTP}>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <br />
                <br />
                <button type="submit" disabled={loading}>
                  {loading ? "Sending OTP..." : "Send OTP"}
                </button>
              </form>
            ) : (
              // Step 2: Verify OTP and Set New Password
              <form onSubmit={handleVerifyOTP}>
                <input
                  type="text"
                  name="otp"
                  placeholder="Enter OTP"
                  value={formData.otp}
                  onChange={handleInputChange}
                />
                <br />
                <br />
                <input
                  type="password"
                  name="password"
                  placeholder="Enter new password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <br />
                <br />
                <button type="submit" disabled={loading}>
                  {loading
                    ? "Verifying OTP..."
                    : "Verify OTP and Reset Password"}
                </button>
              </form>
            )}
            <br />
            <Link to="/login">
              <span>Login Here.</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
