import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ListProduct from "./Product/ListProduct";

function AllProducts() {
  return (
    <>
      <div className="home">
        <Navbar />
        <div className="allproducts">
          <p>
            Home <span>{">"} Products</span>
          </p>
          <br />
          <h2>All </h2>
          <h2>Products</h2>
          <br />
          <br />
          <h3>
          We’re gathering innovative product details from our creators and carefully  <br /> curating them for listing on our platform.
          </h3>
        </div>
        <ListProduct />

        <Footer />
      </div>
    </>
  );
}

export default AllProducts;
