import React, { useState } from "react";
import img from "../Assets/Saly-14.png";
import icon from "../Assets/Facebook.png";
import icon2 from "../Assets/apple.png";
import icon3 from "../Assets/google.png";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../common/toast";
import apiRequest from "../common/apiRequest";
import Loader from "../loader/Loader";
import logo from '../Assets/bong.png'

function Signup() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobile: "",
    role: "customer", // Default to customer
    shopName: "",
    shopAddress: "",
    gstNumber: "",
    gstType: ""
  });
  const [selectedRole, setSelectedRole] = useState("customer");
  const [loading, setLoading] = useState(false);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    setFormData({
      ...formData,
      role: event.target.value
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const mobileRegex = /^[6-9]\d{9}$/; // For Indian mobile numbers

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.name) {
      showErrorToast("Name is required");
      return;
    }

    if (!emailRegex.test(formData.email)) {
      showErrorToast("Invalid email format");
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      showErrorToast(
        "Password must contain at least 8 characters, including uppercase, lowercase, number, and special character"
      );
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      showErrorToast("Passwords do not match");
      return;
    }

    if (!mobileRegex.test(formData.mobile)) {
      showErrorToast("Invalid mobile number. Must be 10 digits.");
      return;
    }

    // Innovator-specific validation
    if (selectedRole === "innovator") {
      if (!formData.gstNumber || !formData.gstType) {
        showErrorToast("GST Number and GST Type are mandatory for Innovators");
        return;
      }
    }

    try {
      // Construct data to send based on role
      const dataToSend = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        mobile: formData.mobile
      };

      if (selectedRole === "innovator") {
        dataToSend.shopName = formData.shopName;
        dataToSend.shopAddress = formData.shopAddress;
        dataToSend.gstNumber = formData.gstNumber;
        dataToSend.gstType = formData.gstType;
      }

      setLoading(true);
      const apiPath =
        selectedRole === "innovator" ? "/seller/create" : "/user/create";

      const response = await apiRequest(apiPath, "POST", dataToSend);
      if (response) {
        showSuccessToast("Signup Success");
        navigate('/login')
      }
      console.log("Signup Response:", response);
    } catch (error) {
      showErrorToast("Signup failed. Please try again.");
      console.error("Signup Error:", error);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="login-banner">
        <div className="login-banner-left login-banner-left2">
        <Link to='/'>

<img className="navlogo navlogo2" src={logo}/>
</Link>

          <h2>Sign Up to</h2>
         
     
          <div className="login-banner-vec">
            <p>
              If you already have an account, <br /> you can{" "}
              <Link to="/login">
                <span>Log in here</span>
              </Link>{" "}
              !
            </p>
            <img src={img} alt="signup illustration" />
          </div>
        </div>
        <div className="login-banner-right">
          <div className="login-form">
            <h2>Sign Up</h2>
            <br />
            <br />
            <div className="role-selector" style={{ display: "flex" }}>
              <label
                className={`radio-btn ${
                  selectedRole === "innovator" ? "active" : ""
                }`}>
                <input
                  type="radio"
                  name="role"
                  value="innovator"
                  checked={selectedRole === "innovator"}
                  onChange={handleRoleChange}
                />
                Innovator
              </label>

              <label
                className={`radio-btn ${
                  selectedRole === "customer" ? "active" : ""
                }`}>
                <input
                  type="radio"
                  name="role"
                  value="customer"
                  checked={selectedRole === "customer"}
                  onChange={handleRoleChange}
                />
                Customer
              </label>
            </div>
            <br />
            <form onSubmit={handleSubmit}>
              <input
                name="name"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <br />
              <br />
           
              <input
                name="email"
                type="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <br />
              <br />
    
              <input
                name="mobile"
                type="tel"
                placeholder="Contact number"
                value={formData.mobile}
                onChange={handleInputChange}
              />
              <br />
              <br />
         
              <input
                name="password"
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <br />
              <br />
      
              <input
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <br />
              <br />
       

              {/* Innovator-Specific Fields */}
              {selectedRole === "innovator" && (
                <div>
                  <select
                    name="gstType"
                    value={formData.gstType}
                    onChange={handleInputChange}>
                    <option value="">Select GST type</option>{" "}
                    {/* Placeholder option */}
                    <option value="gstin">GSTIN</option>
                    <option value="compositgstin">COMPOSITGSTIN</option>
                  </select>

                  <br />
                  <br />
            
                  <input
                    name="gstNumber"
                    placeholder="Enter GST number"
                    value={formData.gstNumber}
                    onChange={handleInputChange}
                  />
                  <br />
                  <br />
          
                  <input
                    name="shopName"
                    placeholder="Enter shop name"
                    value={formData.shopName}
                    onChange={handleInputChange}
                  />
                  <br />
                  <br />
                
                  <input
                    name="shopAddress"
                    placeholder="Enter shop address"
                    value={formData.shopAddress}
                    onChange={handleInputChange}
                  />
                  <br />
                  <br />
               
                </div>
              )}

              <button type="submit">Sign Up</button>
              {/* <br />
              <br />
           

              <p>or continue with</p> */}
              {/* <br /> */}
{/*       
              <div className="continuewith">
                <img src={icon} alt="Facebook" />
                <img src={icon2} alt="Apple" />
                <img src={icon3} alt="Google" />
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
