import React, { useState } from "react";
import AllBaseProduct from "./product/AllBasePrdouct";
import AllSoftProduct from "./product/AllSoftProduct";
import AdminNavbar from "../Components/AdminNavbar";
import Footer from "../Components/Footer";

const AdminAllProduct = () => {
  const [activeComponent, setActiveComponent] = useState("product"); // State for toggling

  // Function to toggle between components
  const handleToggle = (component) => {
    setActiveComponent(component);
  };

  return (
    <div className="home">
      <AdminNavbar />
      <div className=" mt-4">
      <div className="text-center text-center2 mb-4">
  <button
    className={`btn mx-2 ${
      activeComponent === "product" ? "btn-primary" : "btn-secondary"
    }`}
    style={{
      backgroundColor: activeComponent === "product" ? "rgba(115, 90, 229, 1)" : "white",
      color: activeComponent === "product" ? "white" : "rgba(115, 90, 229, 1)",
      borderColor: "rgba(115, 90, 229, 1)"
    }}
    onClick={() => handleToggle("product")}
  >
    Product
  </button>
  <button
    className={`btn mx-2 ${
      activeComponent === "softproduct" ? "btn-primary" : "btn-secondary"
    }`}
    style={{
      backgroundColor: activeComponent === "softproduct" ? "rgba(115, 90, 229, 1)" : "white",
      color: activeComponent === "softproduct" ? "white" : "rgba(115, 90, 229, 1)",
      borderColor: "rgba(115, 90, 229, 1)"
    }}
    onClick={() => handleToggle("softproduct")}
  >
    Projects
  </button>
</div>


        <div className="product-display">
          {activeComponent === "product" ? (
            <AllBaseProduct />
          ) : (
            <AllSoftProduct />
          )}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default AdminAllProduct;
