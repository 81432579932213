import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiRequest from "../../common/apiRequest";
import Loader from "../../loader/Loader";
import { showErrorToast, showSuccessToast } from "../../common/toast";
import cart from "../../Assets/cart.png"; // Cart icon for add to cart functionality

const SoftProductList = () => {
  const navigate = useNavigate();
  const [softProducts, setSoftProducts] = useState([]); // State to hold the list of soft products
  const [loading, setLoading] = useState(true); // Loader state
  const [cartUpdating, setCartUpdating] = useState(false);

  // Fetch soft products from the API
  useEffect(() => {
    const fetchSoftProducts = async () => {
      setLoading(true);
      try {
        const response = await apiRequest("/softproduct/all", "GET"); // API request to fetch soft products
        setSoftProducts(response?.data || []); // Assuming the response contains the soft products
        // showSuccessToast('Soft Products loaded successfully'); // Success toast
      } catch (error) {
        console.error("Error fetching soft products:", error);
        showErrorToast('Failed to load soft products'); // Error toast
      } finally {
        setLoading(false);
      }
    };

    fetchSoftProducts();
  }, []);

  // Handle add to cart functionality
  const handleAddToCart = async (productId) => {
    setCartUpdating(true);
    try {
      const requestBody = {
        productId: productId,
        quantity: 1,
        productType: "SoftProduct",
        actionType: "add",
      };

      const response = await apiRequest("/cart/modify", "POST", requestBody);
      if (response?.data) {
        showSuccessToast("Soft product added to cart");
      }
    } catch (error) {
      console.error("Error adding soft product to cart:", error);
      showErrorToast("Error adding soft product to cart");
    } finally {
      setCartUpdating(false);
    }
  };

  return (
    <div className="soft-products-div">
      <div className="products-div-text">
        {loading && <Loader />}
        <h3>Soft Products</h3>
        <p>
          Showing {softProducts?.length || 0} Soft Products
        </p>
      </div>
      <br />
      <div className="filter">
        <p>Filters</p>
      </div>
      <br />
      <br />

      <div className="shipping-cards">
        {softProducts.map((softProduct, index) => (
          <div key={index}>
            <div className="shipping-card">
              <img
                src={softProduct?.productImage || "default_image.png"} 
                alt={softProduct?.productName}
                onClick={() => navigate(`/softproductdetail/${softProduct?._id}`)}
                style={{ cursor: "pointer" }}
              />{" "}
              {/* Default image fallback */}
              <br />
              <br />
              <h3>{softProduct?.productName}</h3>
              <p>Type: {softProduct?.productType}</p>
              <p>Version: {softProduct?.softwareVersion}</p>
              <p>Seller: {softProduct?.sellerId?.name || "Unknown Seller"}</p>
              <br />
              <br />
              <div className="product-prise">
              <p>INR{softProduct?.price}</p>
                <img
                  src={cart}
                  alt="Add to Cart"
                  onClick={() => handleAddToCart(softProduct._id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />

      <div className="center">
        <button className="allpdt-btn">View More</button>
      </div>
    </div>
  );
};

export default SoftProductList;
