import React, { useEffect, useState } from "react";
import sellerapiRequest from "../common/sellerapiRequest";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";

function ProductTable() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await sellerapiRequest("/product/self", "GET");
        if (response.data) {
          setProducts(response.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <div className="newover">
      {loading && <Loader />}
      <h3>Product List</h3>
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>Image</th>
            <th>Product Name</th>
            <th>Category</th>
            <th>Stock</th>
            <th>Price</th>
            <th>Seller</th>
            <th>Stage</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
              <td>
                {/* Display the first available image, or a default image if none exists */}
                {product.images && product.images.image1 ? (
                  <img
                    src={product.images.image1}
                    alt={product.productName}
                    width="50"
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td>{product.productName}</td>
              <td>
                {product.category ? product.category.name : "No Category"}
              </td>
              <td>{product.quantityInStock}</td>
              <td>${product.salePrice}</td>
              <td>
                {product.sellerId ? product.sellerId.name : "Unknown Seller"}
              </td>
              <td>{product.stage}</td>
              <td>
                <button className="btn btn-primary" onClick={()=>navigate(`/edit-product/${product?._id}`)}>Edit</button>
                {/* <button className="btn btn-danger">Delete</button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductTable;
